import getInstance from './httpClient';

const client = getInstance(process.env.REACT_APP_CBE_ADN_API);
/**
 * Retrieves enterprise data based of a bce number.
 *
 * This asynchronous function sends an HTTP GET request to the `/lookup/:bce`.
 * It returns the entreprise link the the bce number.
 *
 * @param {string} bce - The enterprise bce number. This shoud be a string of 10 char long representing a bce number.
 * @param {string} token - The user token. This should be a encoded JWT.
 * @returns {Promise<Object>} - A promise that resolves to a enterprise object.
 * @throws {Error} - Throws an error if the HTTP request fails.
 */
async function getEnterpriseData(bce, token) {

    const config = {
        method: 'get',
        url: `?bce=${bce}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    try {
        const response = await client.request(config);
        console.log("CBE : ", response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export {
    getEnterpriseData
}

