import axios from 'axios';

let instances = {};

/**
 * Returns a singleton instance of an HTTP client
 * 
 * @param {Object} [options={}] - Optional configuration options for the HTTP client.
 * @returns {Object} - The singleton instance of the HTTP client.
 */
function getInstance(url, options = {}) {
  if (!instances[url]) {
    instances[url] = createHttpClient(url, options)
  }
  return instances[url];
}

/**
 * Create and configures an HTTP client instance
 * 
 * This function craete a new HTTP client with axios with the provided configuration options
 * @param {Object} options - Configuration options for the HTTP client.
 * @returns 
 */
function createHttpClient(url, options) {
  const defaultConfig = {
    baseURL: url,
    headers: { 
      'Content-Type': 'application/json'
    },
    timeout: 30000,
    retryOnError: true,
    logHandler: (level, data) => {
      if (level === 'error' && data) {
        const title = [data.name, data.message].filter((a) => a).join(' - ')
        console.error(`[error] ${title}`)
        console.error(data)
        return
      }
    },
    maxBodyLength: Infinity,
  }

  const config = {
      ...defaultConfig,
      ...options
  }

  const axiosOptions = {
      baseURL: config.baseURL,
      headers: config.headers,
      timeout: config.timeout,
      logHandler: config.logHandler,
      retryOnError: config.retryOnError
  }

  return axios.create(axiosOptions);
}

export default getInstance;