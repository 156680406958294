import { Dialog, Backdrop, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './SurveyDialog.css';
import { useTranslation } from "react-i18next";

/**
 * This component serve as common dialog box. It is a wrapper component, its content is handle through its children.
 */
function SurveyDialog({
  isOpen,
  isLoading,
  close,
  error,
  confirm,
  children
}) {

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress
          color="inherit"
          sx={{
            width: '60px !important',
            height: '60px !important',
            color: '#ffffff !important'
          }}
        />
      </Backdrop>
    )
  }

  return (
    <Dialog
      open={isOpen}
      className="survey-dialog"
      fullWidth
      maxWidth="sm"
    >
      <div className="survey-dialog__container">
        <div className='survey-dialog__toolbar'>
          <CloseIcon onClick={close} />
        </div>
        <div className='survey-dialog__content'>
          <div className='survey-dialog__selection'>
            {error || children}
          </div>
        </div>
        <div className='survey-dialog-btn__container'>
          {error ? undefined :
            (
              <>
                <button onClick={close} className='survey-dialog-btn btn'>{t('survey.compare.buttonCancel')}</button>
                <button onClick={confirm} className='survey-dialog-btn btn btn-confirm'>{t('survey.compare.buttonConfirm')}</button>
              </>
            )}
        </div>
      </div>
    </Dialog>
  );
}

export default SurveyDialog;