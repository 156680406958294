import { useEffect, useState, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";

export const TokenContext = createContext(null);

/**
 * AuthWrapper component is responsible for handling authentication using Auth0.
 * It ensures that the user is authenticated and provides an encrypted access token to its children via a context provider.
 *
 * @component
 *
 * @param {string} props.returnTo - The URL to redirect to after successful authentication.
 * @param {React.ReactNode} props.children - The child components that require authentication.
 *
 * @returns {React.ReactElement|undefined} Returns the children components wrapped in a TokenContext.Provider 
 * with the encrypted token if authenticated. Returns a Loading component if not authenticated while waiting for authentification
 * return undefined in any other case.
 */
function AuthWrapper({ returnTo, children }) {
	const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
	const params = useParams();

	const [token, setToken] = useState();

	useEffect(() => {
		async function fetchToken() {
			try {
				const accessToken = await getAccessTokenSilently({
					audience: process.env.REACT_APP_AUTH0_AUDIENCE
				});

				setToken(accessToken);
			} catch (error) {
				console.error(error);
			}
		}

		// Is user not authenticated?
		if (!isLoading && !isAuthenticated) {
			// Redirect to Auth0 login page.
			const redirectUrl = `${returnTo}/${Object.values(params).join('/')}`
			loginWithRedirect({
				appState: {
					returnTo: redirectUrl
				}
			});
		} else {
			// Is token not in memory?
			if (!token) {
				// Fetch new token with refresh token.
				fetchToken();
			}
		}

	}, [token, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, params, returnTo]);

	if (!isAuthenticated) {
		return <Loading />
	}

	if (token) {
		return (
			<TokenContext.Provider value={token}>
				{children}
			</TokenContext.Provider>
		)
	}

	return undefined;
}

export default AuthWrapper;