import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <Button
      onClick={() => logout({ logoutParams: { returnTo: window.origin } })}
      variant='outlined'
      className="logout-button"
      style={{
        color: "#d7432b",
        fontWeight: "700",
        backgroundColor: 'none',
        textTransform: 'none',
        fontSize: '16px',
        border: 'none',
      }}
      endIcon={<LogoutIcon />}
    >
      {t('logout.text')}
    </Button>
  );
};

export default LogoutButton;
