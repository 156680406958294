import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

/**
 * This component integrates the Auth0Provider from auth0-react to enable authentication and authorization throughout the application.
 * 
 * To utilize the `useNavigate` hook from react-router-dom in the redirection callback provided by Auth0Provider, this component acts as a child of BroswerRouter.
 * The utilization of this hook instead of an other form of redirection (that would bypass react-router-dom), is decided in order to keep all internal navigation throughout the application coherent.
 * 
 * The `useNavigate` hook allows us to redirect users efficiently, sending them to their intended destination immediately after login, thereby enhancing the user experience by minimizing unnecessary navigation. 
 */
function Auth0ProviderWithNavigate({ children }) {
    const navigate = useNavigate();
    
    function onRedirectCallback(appState) {
        navigate(appState?.returnTo || window.location.pathname, { replace: true })
    }

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithNavigate;