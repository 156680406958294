import './SurveyItem.css'
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

function convertDate(date) {
  let convertedDate;
  if (date) {
    convertedDate = format(new Date(date), 'dd/MM/yyyy HH:mm');
  }
  return convertedDate;
}

function MinSurveyItem({
  data,
  enterpriseName,
  bce,
  sector,
  selectHandler
}) {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  function convertStatusText(statusTag) {
    if (statusTag === 'completed') return t('survey.list.completed');
    if (statusTag === 'not-started') return t('survey.list.not-started');
    if (statusTag === 'in-progress') return t('survey.list.started');
  }

  return (
    <div className="survey-item">
      <div className='enterprise__header'>
        <h3 className='enterprise__title'><span className='enterprise-name__title'>{enterpriseName}</span> <span className='enterprise-info__title'>(N° BCE: {bce}) <strong>/</strong> {sector}</span></h3>
      </div>
      <table className='mini-item__table'>
        <thead>
          {Array(windowWidth > 1200 ? 1 : data.length).fill().map((_, i) => {
            return (
              <tr key={i}>
                <th></th>
                <th>{t('survey.list.version')}</th>
                <th>{t('survey.list.updated')}</th>
                <th>{t('survey.list.status')}</th>
                <th></th>
              </tr>
            )
          })}
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <tr key={i}>
                <td><input type="radio" id={`radio-${i}`} name="survey-compare" value={item._id} onClick={() => selectHandler(item._id)} /></td>
                <td>{item.namespace ? `${t('survey.list.questionnaire')} ${item.namespace}` : t('survey.list.survey2020')}</td>
                <td>{convertDate(item.updatedAt)}</td>
                <td><span className={`status-tag ${item.status}`}>{convertStatusText(item.status)}</span></td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MinSurveyItem;
