import { lineRadial, scaleLinear, scaleBand } from "d3";
import { useState, useEffect } from "react";
import RadarGrid from "./RadarGrid";
import './Radar.css'

function Radar({
  width,
  height,
  data,
  axisConfig,
}) {

  const [selectNames, setSelectName] = useState([])

  const polarToCartesian = (angle, distance) => {
    const x = distance * Math.cos(angle);
    const y = distance * Math.sin(angle);
    return { x, y };
  };

  const COLORS = [
    'rgba(54, 162, 235, 1)',
    '#f5aa67',
    '#b03164',
  ];

  const MARGIN = 60;
  const [svg, setSvg] = useState();

  const selectDataHandler = (name) => {
    if (selectNames.includes(name)) {
      setSelectName(selectNames.filter(n => n !== name));
    } else {
      setSelectName([...selectNames, name]);
    }
  }

  useEffect(() => {
    const INNER_RADIUS = 20;
    const OUTER_RADIUS = Math.min(width, height) / 2 - MARGIN

    const variables = axisConfig.map((axis) => axis.name);

    // Axis Scale
    const xScale = scaleBand()
      .domain(variables)
      .range([0, 2 * Math.PI]);

    // Radial Scale
    const radialScale = scaleLinear()
      .domain([0, 100])
      .range([INNER_RADIUS, OUTER_RADIUS]);

    // Path builder
    const lineGenerator = lineRadial();

    const circles = data.map((item, i) => {
      return axisConfig.map((axis, k) => {
        if (item[axis.name]) {
          // I don't know why there is a π rad difference here
          const angle = xScale(axis.name) - (90 * (Math.PI / 180));
          const distance = radialScale(item[axis.name]);
          const coords = polarToCartesian(angle, distance)
          if (!selectNames.includes(item.name)) {
            return (
              <g key={k}>
                <circle
                  className='data-point'
                  cx={coords.x}
                  cy={coords.y}
                  r={4.5}
                  fill={COLORS[i]}
                  fillOpacity={0.3}
                  stroke={COLORS[i]}
                />
                <g className="data-point-tooltip">
                  <rect
                    x={coords.x - 40}
                    y={coords.y - 35}
                    width={80}
                    height={30}
                    fill={COLORS[i]}
                    fillOpacity={0.7}
                    rx={3}
                  />
                  <text
                    x={coords.x}
                    y={coords.y - 13}
                    textAnchor="middle"
                    dominantBaseline='middle'
                    stroke="white"
                    fill="white"
                    style={{
                      fontSize: '0.8em'
                    }}
                  >
                    <tspan x={coords.x} y={coords.y - 26}>{axis.name}</tspan>
                    <tspan x={coords.x} y={coords.y - 13}>{item[axis.name]}</tspan>
                  </text>
                </g>
              </g>
            );
          }
        }
        return null;
      })
    });

    const lines = data.map((item) => {
      const axisCoordinates = axisConfig.map((axis) => {
        const angle = xScale(axis.name);
        const radius = radialScale(item[axis.name]);
        return [angle, radius];
      });

      axisCoordinates.push(axisCoordinates[0]);

      const linePath = lineGenerator(axisCoordinates);

      return { line: linePath, name: item.name };
    });

    const svg = (
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <RadarGrid
          width={width}
          height={height}
          outerRadius={OUTER_RADIUS}
          xScale={xScale}
          yScale={radialScale[axisConfig[0].name]}
          axisConfig={axisConfig}
          max={100}
        />
        {lines.map((item, i) => {
          if (!selectNames.includes(item.name)) {
            return (
              <g key={i}>
                <path
                  d={item.line}
                  stroke={COLORS[i]}
                  strokeWidth={3}
                  fill={COLORS[i]}
                  fillOpacity={0.1}
                  style={{
                    pointerEvents: 'none'
                  }}
                />
                {circles}
              </g>
            )
          }
          return null;
        })}
      </g>
    );

    setSvg(svg);
    // eslint-disable-next-line
  }, [selectNames, data, width, height])

  return (
    <div className="radar">
      <svg width={width + MARGIN} height={height}>
        {svg}
      </svg>
      <div
        className="filter"
      >
        {data.map((item, i) => {
          if (item.infrastructure === undefined) {
            return undefined;
          }

          return (
            <div
              key={i}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                textDecoration: `${!selectNames.includes(item.name) ? 'none' : 'line-through'}`
              }}
              onClick={() => selectDataHandler(item.name)}
            >
              <span style={{
                display: 'block',
                width: '50px',
                height: '20px',
                backgroundColor: `${COLORS[i]}`,
                marginRight: '10px'
              }}></span>
              {item.name}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default Radar;