import { useEffect, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from "react-i18next";
import './Pagination.css';

function Pagination({
  nItemByPage,
  children,
  dependency
}) {
  const { t } = useTranslation();
  const totalPage = Math.ceil(children.length / nItemByPage);
  const [page, setPage] = useState(0);
  const [displayedPageOptions, setDisplayedPageOptions] = useState([]);

  function pageChangeHandler(newPage) {
    if (newPage >= 0 && newPage < totalPage) {
      setPage(newPage);
    }
  }

  useEffect(() => {
    setDisplayedPageOptions(computePaginationOptions());
  // eslint-disable-next-line
  }, [page, children.length]);

  useEffect(() => {
    setPage(0);
  }, [dependency]);

  function computePaginationOptions() {
    let newDisplayedOptions = [];

    if (totalPage <= 1) {
      return newDisplayedOptions;
    }

    newDisplayedOptions.push(0);

    if (totalPage <= 8) {
      for (let i = 1; i < totalPage - 1; i++) {
        newDisplayedOptions.push(i);
      }
      newDisplayedOptions.push(totalPage - 1);
      return newDisplayedOptions;
    }

    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPage - 2, page + 2);

    if (page <= 2) {
      endPage = 5;
    } else if (page >= totalPage - 3) {
      startPage = totalPage - 6;
    }

    if (startPage > 1) {
      newDisplayedOptions.push('start');
    }

    for (let i = startPage; i <= endPage; i++) {
      newDisplayedOptions.push(i);
    }

    if (endPage < totalPage - 2) {
      newDisplayedOptions.push('end');
    }

    newDisplayedOptions.push(totalPage - 1);

    return newDisplayedOptions;
  }

  function handleEllipsisClick(position) {
    if (position === 'start') {
      setPage(Math.max(0, page - 5));
    } else if (position === 'end') {
      setPage(Math.min(totalPage - 1, page + 5));
    }
  }

  if (children.length <= nItemByPage) {
    return <>{children}</>;
  }

  return (
    <div className="pagination">
      {children.slice(nItemByPage * page, nItemByPage * (page + 1))}
      <div className="paginator">
        <div className="paginator__chevron" onClick={() => pageChangeHandler(page - 1)}>
          <ChevronLeftIcon fontSize="large" />
          <span className="paginator__text">{t('survey.pagination.previous')}</span>
        </div>
        {displayedPageOptions.map((pageNumber, index) => (
          pageNumber === 'start' || pageNumber === 'end' ? (
            <p 
              key={index} 
              onClick={() => handleEllipsisClick(pageNumber)} 
              className="paginator__page ellipsis"
            >
              ...
            </p>
          ) : (
            <p 
              key={index} 
              onClick={() => pageChangeHandler(pageNumber)} 
              className={`paginator__page ${pageNumber === page ? 'current' : ''}`}
            >
              {pageNumber + 1}
            </p>
          )
        ))}
        <div className="paginator__chevron" onClick={() => pageChangeHandler(page + 1)}>
          <span className="paginator__text">{t('survey.pagination.next')}</span>
          <ChevronRightIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
