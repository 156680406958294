import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import './LegalInformationPage.css';

function RessourcesPage() {
	const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  function goToHome() {
    navigate('/', { state: { from: location.pathname } });
  }

	return (
		<div className="legalpage">
			<div className="intro">
        <div className="">
          <h1 className="">{t('home.useofdata.title')}</h1>
        </div>
      </div>
      <div className="">
        <p>{t('home.useofdata.p0')}</p>
        <p>{t('home.useofdata.p1')}</p>
        <p>{t('home.useofdata.p2')}</p>
        <p>{t('home.useofdata.p3')}<a href="https://www.digitalwallonia.be/fr/vie-privee" target="_blank" rel="noreferrer">{t('home.useofdata.p3a')}</a></p>
        <br />
        <br />
        <div className="block-text-image__button">
          <button onClick={goToHome} className="btn btn-fullwidth btn-gradient">{t('home.goHome')}</button>
        </div>
      </div>
		</div>
	);
}

export default RessourcesPage;