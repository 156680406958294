import { useTranslation } from "react-i18next";
import './HowToRead.css';

function HowToRead() {

    const { t } = useTranslation();

    return (
        <div className='how-to-result'>
          <h3>{t('result.content.title')}</h3>
          <div className='chapter__container'>
            <section className='chapter'>
              <h3>{`1) ${t('result.content.chapter1.title')}`}</h3>
              <p>{t('result.content.chapter1.p1')}</p>
              <p>{t('result.content.chapter1.p2')}</p>
              <p>{t('result.content.chapter1.p3')}</p>
              <p>{t('result.content.chapter1.p4')}</p>
              <ul>
                <li>
                  <h4>1) {t('result.content.chapter1.li1.title')}</h4>
                  <p>{t('result.content.chapter1.li1.content')}</p>
                </li>
                <li>
                  <h4>2) {t('result.content.chapter1.li2.title')}</h4>
                  <p>{t('result.content.chapter1.li2.content')}</p>
                </li>
                <li>
                  <h4>3) {t('result.content.chapter1.li3.title')}</h4>
                  <p>{t('result.content.chapter1.li3.content')}</p>
                </li>
                <li>
                  <h4>4) {t('result.content.chapter1.li4.title')}</h4>
                  <p>{t('result.content.chapter1.li4.content')}</p>
                  <p>{t('result.content.chapter1.p5')}</p>
                </li>
              </ul>
              <table>
                <thead>
                  <tr>
                    <th>{t('result.content.chapter1.table.head.th1')}</th>
                    <th>{t('result.content.chapter1.table.head.th2')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('result.content.chapter1.table.row1.title')}</td>
                    <td>
                      <span>{t('result.content.chapter1.table.row1.c1')}</span>
                      <span>{t('result.content.chapter1.table.row1.c2')}</span>
                      <span>{t('result.content.chapter1.table.row1.c3')}</span>
                      <span>{t('result.content.chapter1.table.row1.c4')}</span>
                      <span>{t('result.content.chapter1.table.row1.c5')}</span>
                      <span>{t('result.content.chapter1.table.row1.c6')}</span>
                      <span>{t('result.content.chapter1.table.row1.c7')}</span>
                      <span>{t('result.content.chapter1.table.row1.c8')}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('result.content.chapter1.table.row2.title')}</td>
                    <td>
                      <span>{t('result.content.chapter1.table.row2.c1')}</span>
                      <span>{t('result.content.chapter1.table.row2.c2')}</span>
                      <span>{t('result.content.chapter1.table.row2.c3')}</span>
                      <span>{t('result.content.chapter1.table.row2.c4')}</span>
                      <span>{t('result.content.chapter1.table.row2.c5')}</span>
                      <span>{t('result.content.chapter1.table.row2.c6')}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('result.content.chapter1.table.row3.title')}</td>
                    <td>
                      <span>{t('result.content.chapter1.table.row3.c1')}</span>
                      <span>{t('result.content.chapter1.table.row3.c2')}</span>
                      <span>{t('result.content.chapter1.table.row3.c3')}</span>
                      <span>{t('result.content.chapter1.table.row3.c4')}</span>
                      <span>{t('result.content.chapter1.table.row3.c5')}</span>
                      <span>{t('result.content.chapter1.table.row3.c6')}</span>
                      <span>{t('result.content.chapter1.table.row3.c7')}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('result.content.chapter1.table.row4.title')}</td>
                    <td>
                      <span>{t('result.content.chapter1.table.row4.c1')}</span>
                      <span>{t('result.content.chapter1.table.row4.c2')}</span>
                      <span>{t('result.content.chapter1.table.row4.c3')}</span>
                      <span>{t('result.content.chapter1.table.row4.c4')}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className='chapter'>
              <h3>{`2) ${t('result.content.chapter2.title')}`}</h3>
              <p>{t('result.content.chapter2.p1')}</p>
              <p>{t('result.content.chapter2.p2')}</p>
              <p>{t('result.content.chapter2.p3')}</p>
              <p>{t('result.content.chapter2.p4')}</p>
            </section>
            <section className='chapter'>
              <h3>{`3) ${t('result.content.chapter3.title')}`}</h3>
              <p>{t('result.content.chapter3.p1')}</p>
              <p>{t('result.content.chapter3.p2')}</p>
            </section>
          </div>
        </div>
    );
}

export default HowToRead;