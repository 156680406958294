import { lineRadial, scaleLinear } from "d3";
import { useTranslation } from "react-i18next";

function RadarGrid({
  width,
  height,
  outerRadius,
  xScale,
  axisConfig,
  max
}) {

  const { t } = useTranslation();

  const polarToCartesian = (angle, distance) => {
    const x = distance * Math.cos(angle);
    const y = distance * Math.sin(angle);
    return { x, y };
  };

  const MARGIN = 60;
  const INNER_RADIUS = 20;
  const OUTER_RADIUS = Math.min(width, height) / 2 - MARGIN
  const GRID_NUMBER = 5;
  const GRID_COLOR = '#ddd';

  const radialScale = scaleLinear()
    .domain([0, max])
    .range([INNER_RADIUS, OUTER_RADIUS]);

  let ticks = [];
  for (let i = 0; i <= GRID_NUMBER; i++) {
    ticks.push(i * (max / GRID_NUMBER));
  }


  const lineGenerator = lineRadial();

  const axes = axisConfig.map((axis, i) => {
    const angle = xScale(axis.name);

    if (angle === undefined) {
      return null;
    }

    const path = lineGenerator([
      [angle, INNER_RADIUS],
      [angle, outerRadius]
    ]);

    const labelPosition = polarToCartesian(
      angle - Math.PI / 2,
      outerRadius + 15
    );

    return (
      <g key={i}>
        <path
          d={path}
          stroke={GRID_COLOR}
          strokeWidth={0.5}
          rx={1}
        />
        <text
          x={labelPosition.x}
          y={labelPosition.y}
          style={{
            fontSize: '0.8em'
          }}
          fill='black'
          textAnchor={labelPosition.x > 0 ? labelPosition.y !== 0 ? 'middle' : 'start' : 'end'}
          dominantBaseline="middle"
        >
          {t(`result.axis.${axis.name}`)}
        </text>
      </g>
    )
  });

  const circles = ticks.map((value, i) => {
    return (
      <g 
        key={i} 
        className="radial-background-item"
      >
        <circle
          cx={0}
          cy={0}
          r={radialScale(value)}
          stroke={GRID_COLOR}
          fill="none"
        />
        <rect
          x={-15}
          y={-radialScale(value) - 7.5}
          width={30}
          height={15}
          fillOpacity={0.4}
          stroke="grey"
          fill="grey"
          rx={5}
        />
        <text
          x={0}
          y={-radialScale(value)}
          textAnchor="middle"
          style={{
            fontSize: '0.65em',
            opacity: 0.8
          }}
          dominantBaseline='middle'
        >
          {value}
        </text>
      </g>
    );
  });

  return (
    <g>
      {axes}
      {circles}
    </g>
  )
}

export default RadarGrid;