import { useState } from 'react';
import Check from '@mui/icons-material/Check';
import BusinessIcon from '@mui/icons-material/Business';
import HubIcon from '@mui/icons-material/Hub';
import ShareIcon from '@mui/icons-material/Share';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { 
    Stack,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
} from '@mui/material'
import { useTranslation } from 'react-i18next';

function SurveyStepper({ activeStep, setActiveStep }) {
    const { t } = useTranslation();
    const [steps] = useState([
        t('survey.create.stepper.step1'),
        t('survey.create.stepper.step2'),
        t('survey.create.stepper.step3')
    ]);

    const handleStepClick = (index) => {
        if (activeStep === steps.length - 1 && index === 0) {
            setActiveStep(0);
        } else {
            if (index <= activeStep) {
                setActiveStep(index);
            }
        }
    };

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    const QontoStepIcon = (props) => {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    };

    QontoStepIcon.propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        completed: PropTypes.bool,
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 35,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: '#0d93d2',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: '#0d93d2',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#efefef',
        zIndex: 1,
        color: '#fff',
        width: 70,
        height: 70,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor: '#0d93d2',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor: '#0d93d2',
        }),
    }));

    const ColorlibStepIcon = (props) => {
        const { active, completed, className, icon } = props;

        const icons = {
            1: <BusinessIcon style={{ fontSize: 30 }} />,
            2: <HubIcon style={{ fontSize: 30 }} />,
            3: <ShareIcon style={{ fontSize: 30 }} />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(icon)]}
            </ColorlibStepIconRoot>
        );
    };

    ColorlibStepIcon.propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        completed: PropTypes.bool,
        icon: PropTypes.node,
    };

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            optional={<div style={{ color: index <= activeStep ? '#0d93d2' : '' }}>{label}</div>}
                            onClick={() => handleStepClick(index)}
                            sx={{ cursor: index <= activeStep ? 'pointer' : 'default' }}
                        />
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}

SurveyStepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    setActiveStep: PropTypes.func.isRequired,
};

export default SurveyStepper;