import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Trans, useTranslation } from 'react-i18next';
import { filter, isNumber, uniq } from "lodash";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { createSurvey, getNaceWithSector, getPartners } from '../../api/digiscore-api';
import { getEnterpriseData } from '../../api/cbe-api';
import SurveyStepper from '../../components/SurveyStepper/SurveyStepper';
import './SurveyStartPage.css';
import Loading from "../../components/Loading/Loading";

function SurveyStartPage(props) {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [buttonColor, setButtonColor] = useState('#d7432b');
  const [buttonOpacity, setButtonOpacity] = useState('0.5');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState('');
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [partners, setPartners] = useState([]);
  const [errorBce, setErrorBce] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line
  const [sectorOptions, setSectorOptions] = useState([]);
  const [selectedSectorInBackup, setSelectedSectorInBackup] = useState('');
  const [address, setAddress] = useState();
  const [isValidated, setIsValidate] = useState(false);
  const { user } = useAuth0();

  const handleChangeInBackup = (event) => {
    setSelectedSectorInBackup(event.target.value);
  };

  const sectorOptionsInBackup = t('sector', { returnObjects: true });
  const sortedSectorOptions = Object.entries(sectorOptionsInBackup)
    .filter(([key, value]) => key !== 'label' && key !== 'other')
    .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB));
  sortedSectorOptions.push(['other', sectorOptionsInBackup['other']]);

  const handleNext = () => {
    if (activeStep === 1 && (selectedSector || selectedSectorInBackup)) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (activeStep !== 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setSelectedSector(null);
      setSelectedSectorInBackup('');
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    if (!sectors.length) {
      const options = Object.keys(t('sector')).map(key => ({
        value: key,
        label: t('sector')[key]
      })).filter(option => option.value !== 'label');

      setSectorOptions(options);
    }
  }, [sectors, t]);

  const handlePaste = event => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const cleanText = pastedText.replace(/[^\d]/g, '');
    setInputValue(cleanText);
    const syntheticEvent = {
      target: {
        value: cleanText.substring(0, 10)
      }
    };
    handleChange(syntheticEvent);
  };

  const handleChange = event => {
    const text = event.target.value;
    const cleanText = text.replace(/[^\d]/g, '');
    setInputValue(cleanText);
    if (cleanText.length === 9 || cleanText.length === 10) {
      setButtonColor('#16875d');
      setIsButtonDisabled(false);
      setButtonOpacity(1);
    } else {
      setButtonColor('#d7432b');
      setIsButtonDisabled(true);
      setButtonOpacity(0.5);
    }
  };

  const handleSectorChange = event => {
    setSelectedSector(event.target.value);
  };

  function checkFormatEnterpriseNumber(val) {
    if (!val) {
      return false;
    }
    let enterpriseNumberText = val.split(".").join("");
    let enterpriseNumber = Number(enterpriseNumberText);
    if (isNumber(enterpriseNumber)) {
      if (
        !enterpriseNumber.toString().startsWith("0") &&
        enterpriseNumber.toString().length === 9
      ) {
        enterpriseNumberText = "0" + enterpriseNumber;
      }
    }
    return enterpriseNumberText;
  }

  async function storeData() {
    setLoading(true);
    const selectedPartners = partners.filter(partner => partner.checked).map(partner => partner.id);

    const data = {
      bce: inputValue,
      sectorCode: selectedSector || selectedSectorInBackup,
      name: enterpriseName,
      sharedWith: selectedPartners,
      namespace: "BaaS22E",
      address: {
        postCode: address.postCode,
        province: address.province
      },
      user: user
    }

    setIsValidate(true);
    const url = await createSurvey(data, props.token);
    window.location.href = url;
  }


  async function getEnterprise() {
    setLoading(true);
    let searchTextFormatted = checkFormatEnterpriseNumber(inputValue);
    const surveys = JSON.parse(localStorage.getItem('surveys') || '[]');
    const filtered = filter(surveys, { bce: searchTextFormatted });
    let checkStatus = true;
    if (filtered.length > 0) {
      let i = 0;
      while (i < filtered.length && checkStatus) {
        let j = 0;
        while (j < filtered[i]?.surveySessions.length && checkStatus) {
          const status = filtered[i]?.surveySessions[j]?.status;
          checkStatus = status === 'completed';
          j++;
        }
        i++;
      }
    }
    if (checkStatus) {
      try {
        const response = await getEnterpriseData(searchTextFormatted, props.token);
        let sectorArray = [];
        if (response?.content?.enterprise) {
          const responseEnterprise = response?.content?.enterprise;
          try {
            if (responseEnterprise?.activities?.activitiesByNace) {
              for (let i = 0; i < responseEnterprise?.activities?.activitiesByNace.length; i++) {
                sectorArray.push(responseEnterprise?.activities?.activitiesByNace[i]?.code);
              }
              sectorArray = uniq(sectorArray);
              console.log("sectorArray : ", sectorArray)
              const arrayOfSectors = await getNaceWithSector(sectorArray, props.token);
              setSectors(arrayOfSectors);
            }
            setEnterpriseName(responseEnterprise?.identification.name);
            setAddress({
              postCode: responseEnterprise?.identification?.address?.postalCode,
              province: responseEnterprise?.identification?.address?.province?.label
            })
            setLoading(false);
            handleNext();
          } catch (error) {
            setErrorBce('Veuillez vérifier qu\'il s\'agit d\'un numéro BCE valide, svp !');
            setLoading(false);
          }
        } else {
          setErrorMessage(t('survey.create.stepper.errorNotCompany'));
          setLoading(false);
        }
      } catch (error) {
        if (error.code === "ECONNABORTED") {
          setErrorMessage(t('survey.create.stepper.errorTimeout'));
          setLoading(false);
        } else {
          setErrorMessage(t('survey.create.stepper.errorTimeout'));
          setLoading(false);
        }
      }
    } else {
      setErrorMessage(t('survey.create.stepper.errorStarted'));
      setLoading(false);
    }
  }

  const handleCheckboxChange = (partnerId) => {
    const updatedPartners = partners.map(partner =>
      partner.id === partnerId ? { ...partner, checked: !partner.checked } : partner
    );
    setPartners(updatedPartners);
  };

  useEffect(() => {
    const fetchPartners = async () => {
      if (activeStep === 2) {
        try {
          const partnersData = await getPartners(props.token); // Assuming getPartners is your async function
          const partnersWithChecked = partnersData.map(partner => ({ ...partner, checked: false }));
          setPartners(partnersWithChecked);
        } catch (error) {
          console.error('Error fetching partners:', error);
        }
      }
    };

    fetchPartners();
  }, [activeStep, props.token]);

  return (
    <div className="survey">
      {loading && (
        <Loading />
      )}
      <Container className="survey__content" maxWidth="md">
        <Typography variant="h5" align="center" gutterBottom>
          {t('survey.create.title')}
        </Typography>
        <div className="stepper">
          <SurveyStepper activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
        <div style={{ marginTop: "70px", marginBottom: "100px" }}>
          {activeStep === 0 ? (
            <div style={{ marginBottom: '150px' }}>
              <Typography
                style={{ fontWeight: 'bolder' }}
                sx={{ display: 'flex', alignItems: 'center', fontSize: '1.1em', marginBottom: "20px" }}
                component="div"
              >
                {t('survey.create.stepper.step1')}
                <Typography
                  component="span"
                  variant="body2"
                  color="error"
                  sx={{
                    backgroundColor: "#d7432b",
                    color: "#ffffff",
                    fontSize: "10px",
                    padding: "5px 10px",
                    borderRadius: "16px",
                    ml: 1,
                  }}
                >
                  {t('survey.create.stepper.required')}
                </Typography>
              </Typography>
              {errorBce.length > 0 &&
                <p style={{ color: '#d7432b', fontWeight: 'bolder' }}>{errorBce}</p>
              }
              <TextField
                label="Identification de l'entreprise"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  style: {
                    textAlign: 'left',
                    color: errorBce ? '#d7432b' : 'inherit',
                  },
                }}
                InputProps={{
                  style: { textAlign: 'left' },
                  sx: {
                    '& fieldset': {
                      borderColor: errorBce ? '#d7432b' : 'default',
                      borderWidth: errorBce ? 2 : 1,
                    },
                    '&:hover fieldset': {
                      borderColor: errorBce ? '#d7432b' : 'default',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errorBce ? '#d7432b' : 'default',
                      borderWidth: errorBce ? 2 : 1,
                    },
                    '&.Mui-focused .MuiInputLabel-root': {
                      color: 'inherit',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: errorBce ? '#d7432b' : 'default',
                    },
                  },
                }}
                helperText={t('survey.create.stepper.helper')}
                sx={{ width: '100%' }}
                onPaste={handlePaste}
                onChange={handleChange}
                value={inputValue}
                onFocus={() => {
                  setErrorBce('');
                  setErrorMessage('');
                }}
              />
              {errorMessage && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                  padding: '10px',
                  border: '1px solid #d7432b',
                  borderRadius: '5px',
                  backgroundColor: '#ffe6e6'
                }}>
                  <ErrorIcon style={{ color: '#d7432b', marginRight: '8px' }} />
                  <Typography variant="body2" style={{ fontWeight: 'bolder', color: '#d7432b' }}>
                    {errorMessage}
                  </Typography>
                </div>
              )}

              <Button
                onClick={getEnterprise}
                disabled={isButtonDisabled}
                style={{ '&:hover': { backgroundColor: '#0d93d2' }, marginTop: '50px', float: 'right', marginLeft: '15px', backgroundColor: buttonColor, color: '#ffffff', opacity: buttonOpacity }}
              >
                {t('survey.create.stepper.next')}
              </Button>
            </div>
          ) : activeStep === 1 ? (
            <div style={{ marginBottom: '150px' }}>
              <Typography style={{ fontWeight: 'bolder' }} sx={{ display: 'flex', alignItems: 'center', fontSize: '1.1em', marginBottom: "20px" }}>
                {t('survey.create.stepper.step2')}
              </Typography>
              <Typography style={{ color: '#2368a1', fontSize: '1em', fontWeight: '700', marginTop: '32px', display: 'flex', alignItems: 'center' }}>{t('survey.create.stepper.whichCompany')}</Typography>
              <p>{enterpriseName}</p>
              <Typography style={{ color: '#2368a1', fontSize: '1em', fontWeight: '700', marginTop: '32px', display: 'flex', alignItems: 'center' }} component="div">
                {t('survey.create.stepper.mainSector')}
                <Typography
                  variant="body2"
                  color="error"
                  sx={{
                    backgroundColor: "#d7432b",
                    color: "#ffffff",
                    fontSize: "10px",
                    padding: "5px 10px",
                    borderRadius: "16px",
                    ml: 1,
                  }}
                >
                  {t('survey.create.stepper.required')}
                </Typography>
              </Typography>
              {sectors.length ? (
                <RadioGroup
                  aria-label="sectors"
                  name="sectors"
                  value={selectedSector}
                  onChange={handleSectorChange}
                  style={{ width: 'fit-content' }}
                >
                  {[...sectors]
                    .filter(sector => sector?.sectorCode !== "EXC")
                    .sort((a, b) => a.sectorCode.localeCompare(b.sectorCode))
                    .map((sector, index) => (
                      <FormControlLabel
                        key={index}
                        value={sector?.sectorCode}
                        control={
                          <Radio
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 35
                              },
                            }}
                          />
                        }
                        label={t(`sector.${sector?.sectorCode}`)}
                      />
                    ))}
                  <FormControlLabel
                    key="other"
                    value="other"
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 35
                          },
                        }}
                      />
                    }
                    label={t('sector.other')}
                  />
                </RadioGroup>
              ) : (
                <FormControl fullWidth>
                  <InputLabel style={{ marginTop: '25px' }}>{t('sector.label')}</InputLabel>
                  <Select
                    value={selectedSectorInBackup}
                    onChange={handleChangeInBackup}
                    label={t('sector.label')}
                    style={{ marginTop: '25px' }}
                  >
                    {sortedSectorOptions.map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Button
                onClick={handleBack}
                sx={{
                  marginTop: '50px',
                  float: 'left',
                  backgroundColor: '#0d93d2',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0d93d2',
                  },
                }}
              >
                {t('survey.create.stepper.previous')}
              </Button>
              <Button
                onClick={handleNext}
                disabled={activeStep === 1 ? !(selectedSector || selectedSectorInBackup) : false}
                sx={{
                  marginTop: '50px',
                  float: 'right',
                  backgroundColor: '#0d93d2',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0d93d2',
                  },
                }}
              >
                {t('survey.create.stepper.next')}
              </Button>
            </div>
          ) : (
            <div style={{ marginBottom: '150px' }}>
              <Typography style={{ fontWeight: 'bolder' }} sx={{ display: 'flex', alignItems: 'center', fontSize: '1.1em', marginBottom: "20px" }}>
                {t('survey.create.stepper.step3')}
              </Typography>
              <p><Trans i18nKey="survey.create.sharedResult"></Trans></p>

              <ul className="shareList" style={{
                color: '#1c1e1f',
                WebkitFontSmoothing: 'antialiased',
                textSizeAdjust: 'none',
                margin: 0,
                padding: 0,
                border: 0,
                font: 'inherit',
                listStyle: 'none',
                backgroundColor: '#eee',
                paddingBottom: '10px',
                marginBottom: '10px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}>
                {partners.map(partner => (
                  partner.id !== 'ADN' &&
                  <li key={partner.id} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px'
                  }}>
                    <Checkbox
                      id={partner.id}
                      checked={partner.checked}
                      onChange={() => handleCheckboxChange(partner.id)}
                      style={{ color: '#000' }}
                      icon={<span style={{ width: 20, height: 20, border: '1px solid #000', borderRadius: 2 }} />}
                      checkedIcon={<CheckIcon style={{ color: '#0d93d2', border: '1px solid #0d93d2', backgroundColor: '#fff' }} />}
                    />
                    <div style={{
                      backgroundColor: '#fff',
                      margin: '5px 5px 0px 5px',
                      padding: '10px',
                      flexGrow: 1,
                      boxSizing: 'border-box',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                      maxWidth: 'calc(100% - 20px)'
                    }}>
                      <label htmlFor={partner.id} style={{ marginBottom: '2px' }}>
                        <strong>{partner.name}</strong>
                      </label>
                      <p style={{ margin: 0 }}>{partner.description || ''}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <Button
                onClick={handleBack}
                sx={{
                  marginTop: '50px',
                  float: 'left',
                  backgroundColor: '#0d93d2',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0d93d2',
                  },
                }}
              >
                {t('survey.create.stepper.previous')}
              </Button>
              <Button disabled={isValidated} variant="contained" color="success" onClick={() => storeData()} sx={{ marginTop: '50px', float: 'right' }}>
                {t('survey.create.stepper.submit')}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}

export default SurveyStartPage;

