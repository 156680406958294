import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EditProfile.css'

function EditProfile({
  defaultSector,
  sectors,
  selectSectorHandler
}) {

  const { t } = useTranslation();
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedSectorInBackup, setSelectedSectorInBackup] = useState('');

  const handleSectorChange = event => {
    setSelectedSector(event.target.value);
    selectSectorHandler(event.target.value);
  };

  const handleChangeInBackup = (event) => {
    setSelectedSectorInBackup(event.target.value);
    selectSectorHandler(event.target.value);
  };

  const sectorOptionsInBackup = t('sector', { returnObjects: true });
  const sortedSectorOptions = Object.entries(sectorOptionsInBackup)
    .filter(([key, value]) => key !== 'label' && key !== 'other')
    .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB));
  sortedSectorOptions.push(['other', sectorOptionsInBackup['other']]);

  useEffect(() => {
    setSelectedSector(defaultSector);
    setSelectedSectorInBackup(defaultSector)
  }, [defaultSector])

  return (
    <div className='edit-profile'>
      <div className='edit-profile__title'>
        <h1>{t('survey.edit.title')}</h1>
      </div>
      <div>
        {sectors?.length ? (
          <RadioGroup
            aria-label="sectors"
            name="sectors"
            row
            value={selectedSector}
            onChange={handleSectorChange}
            style={{ marginLeft: "15px" }}
          >
            {[...sectors]
              .filter(sector => sector?.sectorCode !== "EXC")
              .sort((a, b) => a.sectorCode.localeCompare(b.sectorCode))
              .map((sector, index) => (
                <FormControlLabel
                  key={index}
                  value={sector?.sectorCode}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 35
                        },
                      }}
                    />
                  }
                  label={t(`sector.${sector?.sectorCode}`)}
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                />
              ))}
            <FormControlLabel
              key="other"
              value="other"
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 35
                    },
                  }}
                />
              }
              label={t('sector.other')}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            />
          </RadioGroup>
        ) : (
          <FormControl fullWidth>
            <InputLabel style={{ marginTop: '25px' }}>{t('sector.label')}</InputLabel>
            <Select
              value={selectedSectorInBackup}
              onChange={handleChangeInBackup}
              label={t('sector.label')}
              style={{ marginTop: '25px' }}
            >
              {sortedSectorOptions.map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
}

export default EditProfile;