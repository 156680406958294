import { useTranslation } from 'react-i18next';

import MinSurveyItem from '../SurveyItem/MinSurveyItem';
import './CompareSession.css';

function CompareSession({
  surveys,
  selectSessionHandler,
  enterpriseName
}) {

  const { t } = useTranslation();

  return (
    <div className='compare-session'>
      <div className='survey-dialog__title'>
        <h1>{t('survey.compare.title').replace('ENTERPRISE', enterpriseName)}</h1>
      </div>
      <p>{t('survey.compare.p1')}</p>
      <div className='survey-list'>
        {surveys.map((item, i) => (
          <MinSurveyItem
            key={i}
            data={item.surveySessions}
            enterpriseName={item.name}
            bce={item.bce}
            sector={t(`sector.${item.sectorCode}`)}
            sectorCode={item.sectorCode}
            selectHandler={selectSessionHandler}
          />
        ))}
      </div>
    </div>
  );
}

export default CompareSession;