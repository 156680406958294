import './App.css';
import './i18n';
import React, { useEffect } from 'react';
import router from './router';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('header.title');
  }, [t]);

  return (
    <Suspense fallback={<div>Loading translations...</div>}>
      <div className="content">
        <Header />
        <div className='main'>
          <Routes>
            {router.map((route, i) => {
              return <Route
                key={i}
                path={route.path}
                element={route.element}
              />
            })}
          </Routes>
        </div>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;