import { useTranslation } from 'react-i18next';
import useDimensions from '../../hook/useDimension';
import Histogram from '../Histogram/Histogram';
import Radar from '../Radar/Radar';
import './SurveyResultComponent.css';

function SurveyResultComponent(props) {

	const [ref, dimensions] = useDimensions();
	const [radarRef, radarDimension] = useDimensions();
	const { t } = useTranslation();

	const axisConfig = [
		{ name: 'infrastructure', max: 100 },
		{ name: 'organization', max: 100 },
		{ name: 'processes', max: 100 },
		{ name: 'strategy', max: 100 },
	]

	console.log("PROPS : ", props)

	return (
		<div className='survey-result-component'>
			<h3>{props.filterTitle}</h3>
			<p className='info1Tour'>{t('result.info1')}<strong>{props.reference?.sample?.Actual} {t('result.bold1')}</strong> {t('result.info2')} <strong>{props.reference?.global?.Mean}</strong></p>
			<div className='survey-result-component__content'>
				<div className='content-element'>
					<div ref={ref} className='chart-histogram'>
						<Histogram
							width={props.histoWidth || dimensions.width}
							height={props.histoHeight || dimensions.height}
							buckets={props.histogramData}
							labels={props.histogramLabels}
							xTitle={t('result.histogram.axis-x')}
							yTitle={t('result.histogram.axis-y')}
							scores={[
								{
									name: `${props.enterprise.name}\n(${(new Date(props.survey.completedAt)).toLocaleDateString()})`,
									value: props.survey?.globalScore
								},
								{
									name: `${props.enterpriseCompare?.name}\n(${(new Date(props.surveyCompare?.completedAt)).toLocaleDateString()})`,
									value: props.surveyCompare?.globalScore
								}
							]}
							mean={props.reference?.global?.Mean}
						/>
					</div>
				</div>
				<div className='content-element'>
					<div ref={radarRef} className='chart-radar'>
						<Radar
							data={props.radarData}
							axisConfig={axisConfig}
							width={radarDimension.width}
							height={radarDimension.height}
						/>
					</div>
					<table className="data-table">
						<thead>
							<tr>
								<th></th>
								<th style={{ width: Object.keys(props.surveyCompare || {}).length !== 0 ? '27%' : '40%', borderBottomColor: "#6cbde4", borderBottomWidth: "2px", borderBottomStyle: "solid"}}>{t('result.table.col1')}</th>
								<th style={{ width: Object.keys(props.surveyCompare || {}).length !== 0 ? '27%' : '40%', borderBottomColor: "#f5aa67", borderBottomWidth: "2px", borderBottomStyle: "solid" }}>{props.enterprise.name}<br />{`(${new Date(props.survey.completedAt).toLocaleDateString()})`}</th>
								{Object.keys(props.surveyCompare || {}).length !== 0 && <th style={{ width: Object.keys(props.surveyCompare || {}).length !== 0 ? '27%' : '40%', borderBottomColor: '#b03164', borderBottomWidth: "2px", borderBottomStyle: "solid" }}>{props.enterpriseCompare.name}<br />{`(${new Date(props.surveyCompare?.completedAt).toLocaleDateString()})`}</th>}
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={props.surveyCompare ? 4 : 3}></td>
							</tr>
							<tr>
								<td>{t('result.axis.infrastructure')}</td>
								<td>{props.reference?.infrastructure?.Mean}</td>
								<td>{props.survey?.infrastructure}</td>
								{props.surveyCompare && <td>{props.surveyCompare?.infrastructure}</td>}
							</tr>
							<tr>
								<td>{t('result.axis.organization')}</td>
								<td>{props.reference?.humanResources?.Mean}</td>
								<td>{props.survey?.humanRessources}</td>
								{props.surveyCompare && <td>{props.surveyCompare?.humanRessources}</td>}
							</tr>
							<tr>
								<td>{t('result.axis.processes')}</td>
								<td>{props.reference?.processFlow?.Mean}</td>
								<td>{props.survey?.processFlow}</td>
								{props.surveyCompare && <td>{props.surveyCompare?.processFlow}</td>}
							</tr>
							<tr>
								<td>{t('result.axis.strategy')}</td>
								<td>{props.reference?.salesProducts?.Mean}</td>
								<td>{props.survey?.salesProducts}</td>
								{props.surveyCompare && <td>{props.surveyCompare?.salesProducts}</td>}
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default SurveyResultComponent;