const { useState, useEffect, useRef } = require("react");

function useDimensions() {

  const getDimensions = () => {
    return {
      width: ref?.current ? ref.current.getBoundingClientRect().width : 0,
      height: ref?.current ? ref.current.getBoundingClientRect().height : 0
    };
  };

  const ref = useRef(null);
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setDimensions(getDimensions());
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  // useLayoutEffect(() => {
  //   handleResize();
  // }, [targetRef]);

  return [ref, dimensions];
}

export default useDimensions;