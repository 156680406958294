import { useTranslation } from "react-i18next";
import './RessourcesPages.css';

function RessourcesPage() {
	const { t } = useTranslation();

	return (
		<div className="ressources-page">
			<h1>{t('ressources.title')}</h1>
			<p>{t('ressources.p1')}</p>
			<ul>
				<li>{t('ressources.li1')}</li>
				<li>{t('ressources.li2')}</li>
				<li>{t('ressources.li3')}</li>
				<li>{t('ressources.li4')}</li>
			</ul>
			<p>{t('ressources.p2')}</p>
			<a href={t('ressources.link')}>{t('ressources.link')}</a>
		</div>
	);
}

export default RessourcesPage;