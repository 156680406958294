import './LogoPartner.css';

export default function LogoPartner({ link, alt, src }) {
    return (
        <div className='partner-item'>
            <div className="partner-logo">
                <a href={link} target="_blank" rel="noreferrer">
                    <img src={src} alt={alt} />
                </a>
            </div>
        </div>
    )
}