import './SurveyItem.css'
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResultFilter from '../ResultFilter/ResultFilter';

function convertDate(date) {
  let convertedDate;
  if (date) {
    convertedDate = format(new Date(date), 'dd/MM/yyyy HH:mm');
  }
  return convertedDate;
}

function SurveyItem({
  data,
  enterpriseName,
  bce,
  sector,
  sectorCode,
  currentUser,
  handleEditProfile,
  handleCompareSession,
  handleShareSession,
  handleModifySession,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  function convertStatusText(statusTag) {
    if (statusTag === 'completed') return t('survey.list.completed');
    if (statusTag === 'not-started') return t('survey.list.not-started');
    if (statusTag === 'in-progress') return t('survey.list.started');
  }

  function onOptionChange(_, key, sessionId, sharedWith) {
    switch (key) {
      case 'result':
        navigate(`result/${sessionId}`);
        break;
      case 'modifyProfile':
        handleEditProfile(bce, sectorCode, sessionId);
        break;
      case 'compare':
        handleCompareSession(sessionId, enterpriseName);
        break;
      case 'share':
        handleShareSession(sessionId, enterpriseName, sharedWith);
        break;
      case 'modifySurvey':
        handleModifySession(sessionId, sectorCode);
        break;
      case 'continue':
        handleModifySession(sessionId, sectorCode);
        break;
      default:
        break;
    }
  }

  return (
    <div className="survey-item">
      <div className='enterprise__header'>
        <h3 className='enterprise__title'><span className='enterprise-name__title'>{enterpriseName}</span> <span className='enterprise-info__title'>(N° BCE: {bce}) <strong>/</strong> {sector}</span></h3>
      </div>
      <table>
        <thead>
          {Array(windowWidth > 1200 ? 1 : data.length).fill().map((_, i) => {
            return (
              <tr key={i}>
                <th>{t('survey.list.version')}</th>
                <th>{t('survey.list.start')}</th>
                <th>{t('survey.list.updated')}</th>
                <th>{t('survey.list.status')}</th>
                <th>{t('survey.list.respondent')}</th>
                <th></th>
              </tr>
            )
          })}
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <tr key={i}>
                <td>{item.namespace ? `${t('survey.list.questionnaire')} ${item.namespace === 'BaaS20E' ? '2020' : '2022'}` : t('survey.list.survey2020')}</td>
                <td>{convertDate(item.startedAt)}</td>
                <td>{convertDate(item.updatedAt)}</td>
                <td><span className={`status-tag ${item.status}`}>{convertStatusText(item.status)}</span></td>
                <td className="respondent-cell">{currentUser.email === item.user.email ? <PersonIcon fontSize='small' /> : ''} <span className='respondent-cell__text'>{item.user.email}</span></td>
                <td>
                  <div className='action__content'>
                    <div className='action__container'>
                      <ResultFilter
                        name={t('survey.list.button')}
                        item
                        options={t(`survey.list.${item.status === 'completed' ?
                          new Date(new Date().setDate(new Date(item.completedAt).getDate() + 15)) < new Date() ? 'completedExpiredOptions' : 'completedOptions'
                          : 'inProgressOptions'
                          }`, { returnObjects: true })}
                        onOptionChange={(_, key) => onOptionChange(_, key, item?._id, item?.sharedWith)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default SurveyItem;
