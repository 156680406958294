import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { finishSurvey, getReferences, publishReport } from '../../api/digiscore-api';
import { useTranslation } from "react-i18next";
import './SurveyResultPage.css';
import ressourcePicture from '../../assets/images/picture-home-1.jpg';
import ressourcePicturex2 from '../../assets/images/picture-home-1@2x.jpg';
import ResultFilter from '../../components/ResultFilter/ResultFilter';
import HowToRead from '../../components/HowToRead/HowToRead';
import SurveyResultComponent from '../../components/SurveyResultComponent/SurveyResultComponent';
import Joyride from 'react-joyride';
import { Backdrop, CircularProgress } from "@mui/material";
import _ from 'lodash';

function findSurvey(enterprises, surveyId) {
  let i = 0;
  while (i < enterprises.length) {
    const { surveySessions } = enterprises[i];
    const survey = surveySessions.find((s) => s._id === surveyId);

    if (survey) {
      return {
        ...enterprises[i],
        surveySessions: [survey]
      }
    }

    i++;
  }
  return undefined;
}

function decodeParams(params) {
  let finalString = '';
  finalString = decodeURIComponent(params);
  finalString = finalString.replace(/&amp;/g, '&');
  finalString = finalString.replace(/&nbsp;/g, '');
  finalString = finalString.replaceAll(/\s/g, '');
  return finalString;
}

function getParams(url) {
  const vars = url.split('&');
  const paramsArray = {};
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    paramsArray[pair[0]] = decodeURIComponent(pair[1]);
  }
  return paramsArray;
}

function findReferenceIndex(references, namespace, dimension, category) {
  if (!references) return undefined;

  const year = namespace === "BaaS20E" ? 2020 : 2022;

  return references.findIndex((ref) => ref.year = year && ref.dimension === dimension && ref.category === category);
}

function SurveyResultPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [enterprise, setEnterprise] = useState({});
  const [enterpriseCompare, setEnterpriseCompare] = useState({});
  const [survey, setSurvey] = useState({});
  const [surveyCompare, setSurveyCompare] = useState({});
  const [references, setReferences] = useState({});
  const [currentReferenceIndex, setCurrentReferenceIndex] = useState();
  const [histogramData, setHistogramData] = useState([]);
  const [histogramLabels, setHistogramLabels] = useState([]);
  const [isInfoTipOpen, setInfoTipOpen] = useState(false);
  const [showJoyride, setShowJoyride] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filterTitle, setFilterTitle] = useState();
  const [filtered, setFiltered] = useState(false);

  const { params } = useParams();

  const data = [
    {
      infrastructure: references[currentReferenceIndex]?.infrastructure?.Mean,
      organization: references[currentReferenceIndex]?.humanResources?.Mean,
      processes: references[currentReferenceIndex]?.processFlow?.Mean,
      strategy: references[currentReferenceIndex]?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ];

  if (surveyCompare) {
    data.push({
      infrastructure: surveyCompare?.infrastructure,
      organization: surveyCompare?.humanRessources,
      processes: surveyCompare?.processFlow,
      strategy: surveyCompare?.salesProducts,
      name: `${enterpriseCompare?.name} (${new Date(surveyCompare?.completedAt).toLocaleDateString()})`,
    })
  }

  const steps = [
    {
      target: '.result',
      content: t('result.tourGuide.one'),
      placement: 'bottom',
      disableBeacon: true,
      spotlightClicks: true,
      styles: {
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          // textColor: '#000',
          borderRadius: '25px',
          boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
          zIndex: 1000,
        },
        tooltip: {
          marginTop: '-10px',
        },
      },
    },
    {
      target: '.result__title',
      content: t('result.tourGuide.two'),
      placement: 'top',
    },
    {
      target: '.highligth-bar',
      content: t('result.tourGuide.three'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.chart-histogram',
      content: t('result.tourGuide.four'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.chart-radar',
      content: t('result.tourGuide.five'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.filterTour',
      content: t('result.tourGuide.six'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.info1Tour',
      content: t('result.tourGuide.seven'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.readResourceTour',
      content: t('result.tourGuide.height'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.downloadTour',
      content: t('result.tourGuide.nine'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
    {
      target: '.shareTour',
      content: t('result.tourGuide.ten'),
      placement: 'top',
      spotlightClicks: true,
      styles: { options: { zIndex: 10000 } }
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const decodedParams = getParams(decodeParams(params));

        let enterpriseResult;
        let enterpriseCompareResult;
        if (decodedParams.LOG && decodedParams.CT) {
          enterpriseResult = await finishSurvey(decodedParams.LOG, decodedParams.CT, "BaaS22E", props.token);
        } else {
          const enterprises = JSON.parse(localStorage.getItem('surveys'));
          if (decodedParams.CUR && decodedParams.COMP) {
            enterpriseResult = findSurvey(enterprises, decodedParams.CUR);
            enterpriseCompareResult = findSurvey(enterprises, decodedParams.COMP);
          } else {
            enterpriseResult = findSurvey(enterprises, params);
          }
        }


        setEnterprise(enterpriseResult);
        setEnterpriseCompare(enterpriseCompareResult || {});
        setSurvey(enterpriseResult.surveySessions[0]);

        if (enterpriseCompareResult) {
          setSurveyCompare(enterpriseCompareResult.surveySessions[0]);
        }

        const response = await getReferences(props.token);
        // const response = await getReferenceByNamespaceCategoryId(enterpriseResult?.surveySessions[0]?.namespace, 'Global', 'GLOB', props.token);
        if (response.length > 1) {
          const refIndex = findReferenceIndex(response, enterpriseResult?.surveySessions[0]?.namespace, 'Global', 'GLOB');
          setReferences(response);
          setCurrentReferenceIndex(refIndex);
          setFilterTitle(t('result.subtitles.global', { year: enterpriseResult.surveySessions[0].namespace === "BaaS20E" ? 2020 : 2022 }));
          const scores = Object.values(response[refIndex].score).map(s => parseFloat(s));
          const labels = Object.keys(response[refIndex].score);
          setHistogramData(scores);
          setHistogramLabels(labels);
        }

        setIsLoading(false);

        const url = await publishReport(enterpriseResult.surveySessions[0]._id, enterpriseResult.bce, enterpriseResult.surveySessions[0].namespace, props.token);
        setPdfUrl(url);

      } catch (error) {
        console.log(error)
        navigate('/error');
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [params, props.token, isLoading]);

  useEffect(() => {
    const hasSeenGuide = localStorage.getItem('hasSeenGuide');
    if (!hasSeenGuide) {
      setShowJoyride(true);
      localStorage.setItem('hasSeenGuide', 'true');
    }
  }, []);

  async function changeReference(dimension, category, titleKey) {
    const refIndex = findReferenceIndex(references, survey?.namespace, dimension, category);

    setCurrentReferenceIndex(refIndex);
    setFiltered(true);
    setFilterTitle(t(`result.subtitles.${titleKey}`, { value: t(`${titleKey}.${category}`).toLowerCase(), year: survey.namespace === 'BaaS22E' ? 2022 : 2020, interpolation: { escapeValue: false } }))
    const scores = Object.values(references[refIndex].score).map(s => parseFloat(s));
    const labels = Object.keys(references[refIndex].score);
    setHistogramData(scores);
    setHistogramLabels(labels);
  }

  function copyShareLinkToClipboard() {
    setInfoTipOpen(true);
    setTimeout(() => {
      setInfoTipOpen(false);
    }, 1100);
    navigator.clipboard.writeText(window.location.href);
  }

  function removeFilter() {
    changeReference('Global', 'GLOB', 'global');
    setFiltered(false);
  }

  async function downloadReportHandler() {
    window.open(pdfUrl, '_blanl');
  }

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress
          color="inherit"
          sx={{
            width: '60px !important',
            height: '60px !important',
            color: '#ffffff !important'
          }}
        />
      </Backdrop>
    )
  }

  return (
    <div className='result'>
      <Joyride
        steps={steps}
        continuous
        spotlightClicks
        showSkipButton
        scrollToFirstStep
        showProgress
        styles={{
          overlay: {
            height: document.body.scrollHeight
          }
        }}
        locale={{
          back: 'Précédent',
          close: 'Fermer',
          last: 'Dernière',
          next: 'Suivant',
          pause: 'Pause',
          resume: 'Reprendre',
          skip: 'Passer',
          start: 'Démarrer',
          stop: 'Arrêter',
          prev: 'Retour',
          done: 'Terminé',
        }}
        run={showJoyride}
        callback={(data) => {
          if (data.status === 'finished' || data.status === 'skipped') {
            setShowJoyride(false);
          }
        }}
      />
      <div className='result__content'>
        <div className='result__title'>
          <h2>{t('result.title')} {enterprise.name}</h2>
        </div>
        <div className='result__body'>
          <SurveyResultComponent
            filterTitle={filterTitle}
            enterprise={enterprise}
            enterpriseCompare={enterpriseCompare}
            reference={references[currentReferenceIndex]}
            survey={survey}
            surveyCompare={surveyCompare}
            histogramData={histogramData}
            histogramLabels={histogramLabels}
            radarData={data}
          />
          <div className='filter filterTour'>
            <h3>{(t('result.filter.title')).toUpperCase()}</h3>
            <div className='filter-global-option'>
              <div className='filter-reset' onClick={removeFilter}>
                {filtered &&
                  <p>{t('result.filter.reset')}</p>
                }
              </div>
            </div>
            <div className='filter-options'>
              <ul>
                <li>
                  <ResultFilter
                    name={t('result.filter.sector')}
                    options={
                      _.pickBy(t('sector', { returnObjects: true }), (value, key) => {
                        return (_.startsWith(key, "C") || _.startsWith(key, "S")) && !_.startsWith(key, "C18")
                      })
                    }
                    dimension='Secteur'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'sector')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.size')}
                    options={t('size', { returnObjects: true })}
                    dimension='Taille'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'size')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.locality')}
                    options={t('province', { returnObjects: true })}
                    dimension='Province'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'province')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.sites')}
                    options={t('site', { returnObjects: true })}
                    dimension='Sites'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'site')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.targetMarket')}
                    options={t('targetMarket', { returnObjects: true })}
                    dimension='Marché'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'targetMarket')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.export')}
                    options={t('export', { returnObjects: true })}
                    dimension='Export'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'export')}
                  />
                </li>
                <li>
                  <ResultFilter
                    name={t('result.filter.finality')}
                    options={t('finality', { returnObjects: true })}
                    dimension='Economie'
                    onOptionChange={(dimension, category) => changeReference(dimension, category, 'finality')}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className='options'>
            <div className={`info-tip ${isInfoTipOpen ? 'open' : ''}`}>
              <p>{t('result.options.copy')}</p>
            </div>
            <button onClick={() => { setShowJoyride(true); }} className='btn btn-alt'>{t('result.options.guide')}</button>
            <button style={{display: 'flex', alignItems: 'center', columnGap: '10px'}} onClick={pdfUrl ? downloadReportHandler : undefined} className={`btn btn-alt downloadTour ${!pdfUrl ? 'btn-inactive' : ''}`}>{pdfUrl ? `${t('result.options.download')}` : `${t('result.options.downloadLoading')}`}{pdfUrl ? undefined : <CircularProgress />}</button>
            <button onClick={copyShareLinkToClipboard} className='btn btn-alt shareTour'>{t('result.options.link')}</button>
          </div>
        </div>
        <HowToRead />
      </div>
      <div className='resssource__container'>
        <div className='box-left'>
          <div className='box-picture'>
            <img
              alt=''
              srcSet={`${ressourcePicture} 623w, ${ressourcePicturex2} 1246w`}
              sizes='(max-width: 480px) 240px, 800px'
              src={ressourcePicture}
            />
          </div>
        </div>
        <div className='box-right'>
          <div className='box-text'>
            <p>{t('result.content.ressource.content')}</p>
            <button onClick={() => navigate('/ressources')} className='btn btn-fullwidth btn-gradient readResourceTour'>{t('result.content.ressource.button')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyResultPage;
