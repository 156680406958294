import {
  Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import './ShareSession.css';

function ShareSession({
  partners,
  initPartners,
  enterpriseName,
  selectPartnersHandler
}) {

  const { t } = useTranslation();

  const [currentPartners, setCurrentPartners] = useState([]);

  useEffect(() => {
    if (!currentPartners || currentPartners?.length === 0) {
      setCurrentPartners(initPartners)
    }
    selectPartnersHandler(currentPartners)
  // eslint-disable-next-line
  }, [initPartners, currentPartners])

  const handleCheckboxChange = (partnerId) => {
    const updatedPartner = currentPartners.filter(partner => {
      return partner !== partnerId
    });

    if (updatedPartner.length === currentPartners.length) {
      setCurrentPartners([...currentPartners, partnerId]);
    } else {
      setCurrentPartners(updatedPartner);
    }
  };

  return (
    <div className='share-dialog'>
      <div className='dialog__title'>
        <h1>{(t('survey.share.title')).replace('{ENTERPRISE}', enterpriseName)}</h1>
      </div>
      <div className='partner-list'>
        <ul className="shareList" style={{
          color: '#1c1e1f',
          WebkitFontSmoothing: 'antialiased',
          textSizeAdjust: 'none',
          margin: 0,
          padding: 0,
          border: 0,
          font: 'inherit',
          listStyle: 'none',
          backgroundColor: '#eee',
          paddingBottom: '10px',
          marginBottom: '10px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}>
          {partners.map(partner => (
            partner.id !== 'ADN' &&
            <li key={partner.id} style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5px'
            }}>
              <Checkbox
                id={partner.id}
                checked={currentPartners ? currentPartners.includes(partner.id) : false}
                onChange={() => handleCheckboxChange(partner.id)}
                style={{ color: '#000' }}
                icon={<span style={{ width: 20, height: 20, border: '1px solid #000', borderRadius: 2 }} />}
                checkedIcon={<CheckIcon style={{ color: '#0d93d2', border: '1px solid #0d93d2', backgroundColor: '#fff' }} />}
              />
              <div style={{
                backgroundColor: '#fff',
                margin: '5px 5px 0px 5px',
                padding: '10px',
                flexGrow: 1,
                boxSizing: 'border-box',
                marginBottom: '10px',
                paddingBottom: '10px',
                maxWidth: 'calc(100% - 20px)'
              }}>
                <label htmlFor={partner.id} style={{ marginBottom: '2px' }}>
                  <strong>{partner.name}</strong>
                </label>
                <p style={{ margin: 0 }}>{partner.description || ''}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ShareSession;