import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
  Backdrop,
  CircularProgress,
  SvgIcon,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText
} from '@mui/material';
import SurveyItem from '../../components/SurveyItem/SurveyItem';
import { getSurveySessionByUser, getPartners, getSectorsByBce, updateSector, updateShareWith, getEthnosUrl } from '../../api/digiscore-api';
import './SurveyPage.css';
import Pagination from '../../components/Pagination/Pagination';
import EditProfile from '../../components/EditProfile/EditProfile';
import CompareSession from '../../components/CompareSession/CompareSession';
import ShareSession from '../../components/ShareSession/ShareSession';
import SurveyDialog from '../../components/SurveyDialog/SurveyDialog';

function CustomAddCircleIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="11" fill="#6f9048" />
      <path d="M12 7v10M7 12h10" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
    </SvgIcon>
  );
}

function SurveyPage(props) {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [currentBce, setCurrentBce] = useState();
  const [currentSector, setCurrentSector] = useState('');
  const [currentEnterpriseName, setCurrentEnterpriseName] = useState();
  const [currentSessionId, setCurrentSessionId] = useState();
  const [currentSharedWith, setCurrentSharedWith] = useState();
  const [partners, setPartners] = useState([]);

  const [sectors, setSectors] = useState({});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogLoading, setDialogLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const [comparedSessionId, setComparedSessionId] = useState();
  const [newSelectedSector, setNewSelectedSector] = useState();
  const [newPartners, setNewPartners] = useState();

  const [dialogContent, setDialogContent] = useState('compare');

  const [updateData, setUpdateData] = useState(false);

  const statuses = [
    { value: 'completed', label: t('survey.list.completed') },
    { value: 'not-started', label: t('survey.list.not-started') },
    { value: 'in-progress', label: t('survey.list.started') },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentBce && !sectors[currentBce]) {
          setDialogLoading(true);

          const sectorsData = await getSectorsByBce(currentBce, props.token);
          setSectors({
            ...sectors,
            [currentBce]: sectorsData,
          });

          setDialogLoading(false);
        }
      } catch (error) {
        console.error(error);
        setDialogError(t('survey.create.stepper.errorPersistantTimeout'));
        setDialogLoading(false);
      }
    }

    fetchData();
  // eslint-disable-next-line
  }, [currentBce, props.token])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const partnersData = await getPartners(props.token);
        setPartners(partnersData);
        const data = localStorage.getItem('surveys');
        if (document.referrer.split('/').at(-1) !== 'start' || !surveys) {
          const response = await getSurveySessionByUser(user, props.token);
          let newSurvey = response || [];
          localStorage.setItem('surveys', JSON.stringify(newSurvey));
          const storedSurveys = localStorage.getItem('surveys');
          if (storedSurveys.length) {
            setSurveys(JSON.parse(storedSurveys));
          }
        } else {
          setSurveys(JSON.parse(data));
        }
        setLoading(false);

      } catch (error) {
        console.error('Error fetching partners:', error);
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [isAuthenticated, user.email, user, updateData, props.token]);

  const handleCompareSession = (sessionId, name) => {
    setCurrentSessionId(sessionId);
    // setDialogContent(() => compareSessionDialog(sessionId));
    setDialogContent('compare')
    setCurrentEnterpriseName(name)
    setDialogOpen(true);
  }

  const handleEditProfile = (bce, sector, sessionId) => {
    setCurrentBce(bce);
    setCurrentSessionId(sessionId);
    setCurrentSector(sector);
    setDialogContent('edit')
    setDialogOpen(true);
  }

  const handleShareSession = (sessionId, name, sharedWith) => {
    setCurrentSessionId(sessionId);
    setCurrentEnterpriseName(name);
    setCurrentSharedWith(sharedWith);
    setDialogContent('share')
    setDialogOpen(true);
  }

  const handleModifySession = async (sessionId, sectorCode) => {
    setDialogLoading(true);
    const url = await getEthnosUrl(sessionId, sectorCode, props.token);
    window.location.href = url;
  }

  function closeDialog() {
    setDialogError(undefined)
    setDialogOpen(false);
    setUpdateData(!updateData);
  }

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedStatuses(typeof value === 'string' ? value.split(',') : value);
  };

  let filteredSurveys = surveys.map(survey => {
    const filteredSessions = survey.surveySessions.filter(session =>
      ((session.user.email && session.user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (survey.bce && survey.bce.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (survey.name && survey.name.toLowerCase().includes(searchQuery.toLowerCase()))) &&
      (selectedStatuses.length === 0 || selectedStatuses.includes(session.status))
    );
    return {
      ...survey,
      surveySessions: filteredSessions
    };
  }).filter(survey => survey.surveySessions.length > 0);

  filteredSurveys = filteredSurveys.sort((a, b) => {
    const latestSessionA = a.surveySessions.reduce((latest, session) => {
      return new Date(session.updatedAt) > new Date(latest.updatedAt) ? session : latest;
    });
    const latestSessionB = b.surveySessions.reduce((latest, session) => {
      return new Date(session.updatedAt) > new Date(latest.updatedAt) ? session : latest;
    });
    return new Date(latestSessionB.updatedAt) - new Date(latestSessionA.updatedAt);
  });

  const totalResults = filteredSurveys.reduce((acc, survey) => acc + survey.surveySessions.length, 0);

  function compareSessionConfirmHandler() {
    if (currentSessionId && comparedSessionId) {
      navigate(`result/CUR=${currentSessionId}&COMP=${comparedSessionId}`);
    }
  }

  function compareSessionDialog() {

    const selectSessionHandler = (sessionId) => {
      setComparedSessionId(sessionId);
    }

    return (
      <CompareSession
        // eslint-disable-next-line
        surveys={surveys.map(enterprise => {
          const filteredSurvey = enterprise.surveySessions.filter(session => session.status === 'completed' && session._id !== currentSessionId);
          if (filteredSurvey.length > 0) {
            return {
              ...enterprise,
              surveySessions: filteredSurvey
            }
          }
        }).filter(enterprise => enterprise?.surveySessions.length > 0)
        }
        sessionId={currentSessionId}
        selectSessionHandler={selectSessionHandler}
        enterpriseName={currentEnterpriseName}
      />
    )
  }

  async function editSessionConfirmHandler() {
    try {
      setDialogLoading(true);
      await updateSector(currentBce, newSelectedSector, currentSessionId, props.token);
      setUpdateData(!updateData);
      setDialogLoading(false);
      setDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  function editSessionDialog() {

    const selectSectorHandler = (newSector) => {
      setNewSelectedSector(newSector);
    } 

    return (
      <EditProfile
        defaultSector={currentSector}
        sectors={sectors[currentBce]}
        selectSectorHandler={selectSectorHandler}
      />
    );
  }

  async function shareSessionConfirmHandler() {
    try {
      setDialogLoading(true);
      await updateShareWith(currentSessionId, newPartners, props.token);
      setUpdateData(!updateData)
      setDialogLoading(false);
      setDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  function shareSessionDialog() {
    
    const selectPartnersHandler = (newPartners) => {
      setNewPartners(newPartners);
    } 

    return (
      <ShareSession
        partners={partners}
        initPartners={currentSharedWith}
        enterpriseName={currentEnterpriseName}
        selectPartnersHandler={selectPartnersHandler}
      />
    );
  }

  return (
    <div className="survey">
      <SurveyDialog
        title={"my title"}
        isLoading={isDialogLoading}
        isOpen={isDialogOpen}
        close={closeDialog}
        error={dialogError}
        confirm={dialogContent === 'compare' ? compareSessionConfirmHandler : dialogContent === 'edit' ? editSessionConfirmHandler : shareSessionConfirmHandler}
      >
        {dialogContent === 'compare' && compareSessionDialog()}
        {(dialogContent === 'edit') && editSessionDialog()}
        {(dialogContent === 'share') && shareSessionDialog()}
      </SurveyDialog>
      <div className="survey-container">
        <div className="survey-header">
          <div className="survey-title">
            {t('survey.list.title')}
          </div>
          <Link to="/survey/start" style={{ textDecoration: 'none' }}>
            <Button
              variant='outlined'
              style={{
                color: "#6f9048",
                fontWeight: "bolder",
                backgroundColor: '#d0e9b3',
                textTransform: 'none',
                fontSize: '16px',
                padding: "10px",
                border: "1px solid #6f9048"
              }}
              startIcon={<CustomAddCircleIcon style={{ fontSize: 30 }} />}
            >
              {t('survey.create.button')}
            </Button>
          </Link>
        </div>
        <div className="survey-controls">

          <div className="filter-control">
            <div className="survey-filter">{t('survey.list.filter')}</div>
            <div className="filter-field filter-search-field">
              <TextField
                label={t('survey.list.search')}
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className='filter-field filter-status-field'>
              <FormControl fullWidth>
                <InputLabel>{t('survey.list.status')}</InputLabel>
                <Select
                  label={t('survey.list.status')}
                  multiple
                  value={selectedStatuses}
                  onChange={handleStatusChange}
                  renderValue={(selected) => selected.map(value => statuses.find(status => status.value === value).label).join(', ')}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      <Checkbox checked={selectedStatuses.indexOf(status.value) > -1} />
                      <ListItemText primary={status.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="result-count">
            {totalResults > 1 ? (
              <>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{totalResults}</span> {t('survey.list.resultsFoundPlural')}
              </>
            ) : (
              <>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{totalResults}</span> {t('survey.list.resultsFoundSingular')}
              </>
            )}
          </div>
        </div>
        <div className="survey-list__container">
          {isLoading ? (
            <Backdrop
              sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  width: '120px !important',
                  height: '120px !important',
                  color: '#ffffff !important'
                }}
              />
            </Backdrop>
          ) : (
            <Pagination
              nItemByPage={10}
              dependency={[filteredSurveys, searchQuery, selectedStatuses]}
            >
              {filteredSurveys.map((item, i) => (
                <SurveyItem
                  key={i}
                  data={item.surveySessions}
                  enterpriseName={item.name}
                  bce={item.bce}
                  sector={t(`sector.${item.sectorCode}`)}
                  sectorCode={item.sectorCode}
                  currentUser={user}
                  handleEditProfile={handleEditProfile}
                  handleCompareSession={handleCompareSession}
                  handleShareSession={handleShareSession}
                  handleModifySession={handleModifySession}
                />
              ))}
            </Pagination>
          )}
        </div>
      </div>
    </div>
  );
}

export default SurveyPage;
