import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scaleBand, scaleLinear } from 'd3';
import Axis from '../Graph/Axis/Axis';
import './Histogram.css';

/**
 * JSX Component for displaying a histogram highlighting the company score(s) and the mean reference value.
 * @param {Object} props - The properties object.
 * @param {Number} props.width - Container width.
 * @param {Number} props.height - Container height.
 * @param {Number[]} props.buckets - Data values for each bar. MUST BE the same length as props.labels.
 * @param {string[]} props.labels - Data labels for each bar. MUST BE the same length as props.buckets.
 * @param {string} props.xTitle - Horizontal axis title.
 * @param {string} props.yTitle - Vertical axis title.
 * @param {Object[]} props.scores - Scores to highlight. This will highlight the buckets corresponding to these values.
 * @param {Number} props.mean - Mean value to highlight. This will highlight the bucket corresponding to this value.
 * @returns {JSX.Element} The JSX element representing the histogram.
 */
function Histogram(props) {

  const { t } = useTranslation();

  // Correspond to the step value between each horizontal line in the background grid.
  const GRID_STEP = 5;
  const HIGHTLIGH_SCORE_COLORS = [
    'rgba(255, 99, 132, 0.5)',
    '#b03164',
    '#f5aa67',
  ]

  const [svg, setSvg] = useState();

  const margins = {
    top: 35,
    bottom: 45,
    left: 50,
    right: 0
  }

  function buildBackground(xScale, yScale, max) {
    return (
      <g className='histogram-background'>
        <g className='grid'>
          {Array.from({ length: max / GRID_STEP }, (_, i) => i * GRID_STEP).map((value, i) => {
            return <line
              key={i}
              x1={margins.left}
              x2={props.width - (margins.right)}
              y1={yScale(value)}
              y2={yScale(value)}
              stroke="#eee"
            />
          })}
          {props.labels.map((label, i) => {
            return <line
              key={i}
              x1={xScale(label) - (xScale.step() * xScale.paddingInner()) / 2}
              x2={xScale(label) - (xScale.step() * xScale.paddingInner()) / 2}
              y1={margins.top}
              y2={props.height - margins.bottom}
              stroke="#eee"
            />
          })}
        </g>
        <g className='highligth'>
          <g
            className='highlighted-bucket'
          >
            <rect
              className='highligth-bar'
              // Let's compute the label that the value belong to (e.g. 24 -> 20-30)
              // Let's make sure that the hightlight bar width is 100% the width of the bucket.
              x={xScale(`${Math.floor(props.mean / 10) * 10}-${((Math.floor(props.mean / 10) + 1) * 10) - 1}`) - xScale.step() * xScale.paddingInner() / 2}
              y={margins.top}
              height={props.height - margins.bottom - margins.top}
              fill={HIGHTLIGH_SCORE_COLORS[0]}
              width={xScale.bandwidth() + xScale.step() * xScale.paddingInner()}
            />
            <text
              className='highligth-text'
              x={xScale(`${Math.floor(props.mean / 10) * 10}-${((Math.floor(props.mean / 10) + 1) * 10) - 1}`) + xScale.bandwidth() / 2}
              y={margins.top - 7}
            >
              {t('result.histogram.mean') + ' : ' + props.mean}
            </text>
          </g>
        </g>
        {[props.scores[1], props.scores[0]].map(({ name, value }, i) => {
          if (value === undefined) {
            return undefined;
          }

          const x = xScale(`${Math.floor(value / 10) * 10}-${((Math.floor(value / 10) + 1) * 10) - 1}`) + xScale.bandwidth() / 2
          const y1 = (props.height + margins.bottom * i) - 10

          return (
            <g
              key={i}
            >
              <line
                // Let's compute the label that the value belong to (e.g. 24 -> 20-30)
                // Let's make sure that the hightlight bar width is 100% the width of the bucket.
                x1={x}
                x2={x}
                y1={y1}
                y2={props.height - margins.bottom}
                stroke={HIGHTLIGH_SCORE_COLORS[i + 1]}
                strokeDasharray={4}
                strokeWidth={2}
              />
              <foreignObject x={x - 140} y={y1 - 20} width="280" height="80">
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    className='highligth-text'
                    style={{
                      padding: '2px 10px',
                      backgroundColor: HIGHTLIGH_SCORE_COLORS[i + 1],
                      borderRadius: '5px',
                      textAlign: 'center',
                      color: i === 0 ? 'white' : 'black'
                    }}
                  >
                    <span>{name}</span>
                    <span>{`score - ${value}`}</span>
                  </div>
                </div>
              </foreignObject>
            </g>
          )
        })}
      </g>
    );
  }

  function buildForeground(xScale, yScale) {
    return (
      <g className='histogram-foreground'>
        {props.buckets.map((bucket, i) => {
          return (
            <g
              key={i}
            >
              <rect
                className='foreground-bar'
                width={xScale.bandwidth()}
                x={xScale(props.labels[i])}
                y={yScale(bucket)}
                height={props.height - yScale(bucket) - margins.bottom}
              />
              <text
                className='foreground-text'
                x={xScale(props.labels[i]) + xScale.bandwidth() / 2}
                y={yScale(bucket) - 5}
              >
                {bucket}
              </text>
            </g>
          );
        })}
      </g>
    )
  }

  useEffect(() => {
    if (props.buckets.length > 0) {
      const xScale = scaleBand()
        .domain(props.labels)
        .paddingOuter(0.1)
        .paddingInner(0.2)
        .range([margins.left, props.width - margins.right]);

      const maxValue = (Math.ceil(Math.max(...props.buckets) / 10) * 10) + GRID_STEP

      const yScale = scaleLinear()
        .domain([0, maxValue])
        .range([props.height - margins.bottom, margins.top]);

      const background = buildBackground(xScale, yScale, maxValue);
      const foreground = buildForeground(xScale, yScale);

      const svg = (
        <g>
          {background}
          <Axis
            width={props.width}
            height={props.height}
            scale={yScale}
            title={props.yTitle}
            labels={Array.from({ length: maxValue / GRID_STEP }, (_, i) => i * GRID_STEP)}
            direction={'vertical'}
            paddings={margins}
            formatFn={(s) => s.toString() + '%'}
          />
          <Axis
            width={props.width}
            height={props.height}
            scale={xScale}
            title={props.xTitle}
            labels={props.labels}
            direction={'horizontal'}
            paddings={margins}
            formatFn={(s) => s}
          />
          {foreground}
        </g>
      )

      setSvg(svg);
    }
    // eslint-disable-next-line
  }, [props])

  return (
    <div className="histogram">
      <svg width={props.width + margins.right + margins.left} height={props.height + margins.top + margins.bottom}>
        <defs>
          <filter x="-0.05" y="-0.1" width="1.1" height="1.2" id="survey-0">
            <feFlood floodColor={HIGHTLIGH_SCORE_COLORS[1]} result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <filter x="-0.05" y="-0.1" width="1.1" height="1.2" id="survey-1">
            <feFlood floodColor={HIGHTLIGH_SCORE_COLORS[2]} result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <clipPath id="rounded-corner">
            <rect x="0" y="0" width="100%" height="100%" rx="30" ry="15" />
          </clipPath>
        </defs>
        {svg}
      </svg>
    </div >
  )
}

export default Histogram;