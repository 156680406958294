import React from 'react';
import { useTranslation } from "react-i18next";
import logo_adn from '../../assets/images/logos/logo-AdN-blanc.png';
import './Footer.css';
import { useLocation } from 'react-router-dom';

const version = "1.2.7";

export default function Footer() {
  const location = useLocation();
  const { t } = useTranslation();

  if (location.pathname.startsWith('/report')) {
    return;
  }

  return (
    <footer className="c-footer">
      <div className="c-footer-main">
        <div className="c-footer-left">
          <p>{ t('footer.partnership') }</p>
          <p>© { new Date().getFullYear() } - { t('footer.byDW') } - <a href='/legalinformation'>{ t('footer.privacyPolicy') }</a></p>
          <div className="c-footer-nav-menu">
            <div className="c-footer-nav-menu-content">
              <p><strong>{ t('footer.contactAdN') }</strong></p>
              <p>Avenue Prince de Liège, 133 <br />5100 Jambes</p>
              <p>tel <a href="tel:+32 (0)81 77 80 80">+32 (0)81 77 80 80</a></p>
              <a href="mailto:support@digitalwallonia.be">{ t('footer.contactSupportByMail') }</a>
              <br />
              <br />
              <p>Version : {version}</p>
            </div>
          </div>
        </div>
        <div className="c-footer-right">
          <div className="c-footer-logos-main">
            <a href="https://www.adn.be" target="_blank" rel="noreferrer"><img src={logo_adn} alt="AdN logo" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}
