import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import logo_digiscore from '../../assets/images/logos/digiscore.png';
import './Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutButton from '../../components/LogoutButton';
import { Link } from 'react-router-dom';

export default function Header() {
  const location = useLocation();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const [isMenuOpen, setMenuOpen] = useState(false);

  function navClickHandler(value) {
    setMenuOpen(value);
  }

  if (location.pathname.startsWith('/report')) {
    return;
  }

  return (
    <header className={`header ${location.pathname === '/' ? 'sticky' : ''}`}>
      <div className="header__content">
        <div className="header__logo">
          <Link to={'/'}>
            <img width="175px" src={logo_digiscore} alt={t('header.logo')}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              }}
            />
          </Link>
        </div>
        <div className={`header__navigation`}>
          <ul className={`navigation ${isMenuOpen ? 'expanded' : ''}`}>
            {(!location.pathname.startsWith('/survey') && !location.pathname.startsWith('/ressources') && !location.pathname.startsWith('/error') && !location.pathname.startsWith('/legalinformation')) ? (
              <>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.why.anchor')}`}>{t('home.why.menu')}</a>
                </li>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.how.anchor')}`}>{t('home.how.menu')}</a>
                </li>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.companySurvey.anchor')}`}>{t('home.companySurvey.menu')}</a>
                </li>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.partners.anchor')}`}>{t('home.partners.menu')}</a>
                </li>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.useofdata.anchor')}`}>{t('home.useofdata.menu')}</a>
                </li>
                <li className='navigation__item' onClick={() => navClickHandler(false)}>
                  <a className="navigation__link" href={`#${t('home.faq.anchor')}`}>{t('home.faq.menu')}</a>
                </li>
              </>
            ) : <>
              {(location.pathname.startsWith('/survey/start') || location.pathname.startsWith('/survey/result') || location.pathname.startsWith('/ressources')) && (
                <li className='navigation__item'>
                  <Link className="navigation__link" to={'/survey'}>{t('survey.header.back-to-diagnosis')}</Link>
                </li>
              )}
              {
                isAuthenticated ? (
                  <li className='navigation__item'>
                    <LogoutButton />
                  </li>
                ) : ''}
            </>
            }
          </ul>
        </div>
        <div className="burger-btn__content">
          <button className="burger-btn" onClick={() => navClickHandler(!isMenuOpen)}>
            {isMenuOpen ?
              (<>
                <span><CloseIcon fontSize='large' /></span>
                <span className="burger-btn__text">{t('header.close')}</span>
              </>) : (
                <>
                  <span><MenuIcon fontSize='large' /></span>
                  <span className="burger-btn__text">{t('header.menu')}</span>
                </>
              )}
          </button>
        </div>
      </div>
    </header >
  );
}
