import getInstance from './httpClient';
import { getEnterpriseData } from './cbe-api';
import { uniqBy, uniq } from 'lodash';

const client = getInstance(process.env.REACT_APP_API_BASE_URL);

/**
 * Retrieves NACE data filtered by a list of sectors.
 *
 * This asynchronous function sends an HTTP GET request to the `/nace` endpoint with a query parameter `filter` that specifies the sectors to include.
 * It returns unique NACE data entries based on the `sectorCode` field.
 *
 * @param {string[]} sectors - A list of sector codes to filter the NACE data. Each element in the array is a string representing a sector code.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of unique NACE data entries, filtered by the `sectorCode` field.
 * @throws {Error} - Throws an error if the HTTP request fails.
 */
async function getNaceWithSector(sectors, token) {
  let filter = {
    Code: {
      "$in": sectors
    }
  }

  const config = {
    method: 'get',
    url: `/nace?filter=${encodeURI(JSON.stringify(filter))}`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  };

  try {
    const response = await client.request(config);
    return uniqBy(response.data, 'sectorCode');
  } catch (error) {
    throw error;
  }
}

/**
 * Retrieves survey session based of a user and its given roles.
 *
 * This asynchronous function sends an HTTP GET request to the `/surveySessoin` endpoint with a query parameter `roles` and `email` that specifies the roles to look for and the email of the user.
 * It return sessions that are shared with at least one of the role of the user or that has been started by him.
 *
 * @param {Object} user - A Auth0 user object containing data about himself and his roles.
 * @returns {Promise<Object[]>} - A promise that resolves to an array of survey session entries.
 * @throws {Error} - Throws an error if the HTTP request fails.
 */
async function getSurveySessionByUser(user, token) {
  const roles = user["https://digitalwallonia.be/role"].map(role => role.split('_').at(-1));
  const email = user["email"].split('_').at(-1);

  const config = {
    method: 'get',
    url: `/surveySession?roles=${encodeURIComponent(JSON.stringify(roles))}&email=${encodeURIComponent(email)}`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  }

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Retrieve all digiscore partners
 * 
 * This asynchronous function sends an HTTP GET request to the `/partners` endpoint.
 * It return all the partners of the digiscore.
 * 
 * @returns {Promise<Object[]>} - A promise that resolve to an array of partners entries.
 * @throws {Error} - Throws an error if the HTTP request fails.
 */
async function getPartners(token) {

  const config = {
    method: 'get',
    url: `/partners`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  };

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Retrieves a survey session based of its session id.
 *
 * This asynchronous function sends an HTTP GET request to the `/surveySession/:id` endpoint.
 * It return a single session.
 *
 * @param {string} id - The id of the session.
 * @returns {Promise<Object>} - A promise that resolves to a survey session entry.
 * @throws {Error} - Throws an error if the HTTP request fails.
 */
async function getSurveySession(id, token) {

  const config = {
    method: 'get',
    url: `/surveySession/${id}`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  };

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getReferenceByNamespaceCategoryId(Namespace, Dimension, Category, token) {
  let filter = {
    Namespace,
    Dimension,
    Category
  };

  const config = {
    method: 'get',
    url: `/reference?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  };

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getReferences(token){

  const config = {
    method: 'get',
    url: `/reference`,
    headers: {
      Authorization : `Bearer ${token}`
    }
  };

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function createSurvey(data, token) {


  const config = {
    method: 'put',
    url: `/surveySession/createSurvey`,
    data: data,
    headers: {
      Authorization : `Bearer ${token}`
    }
  }

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function finishSurvey(login, controlCode, namespace, token) {

  const config = {
    method: 'put',
    url: '/surveySession/finishSurvey',
    data: {
      login: login,
      ct: controlCode,
      namespace: namespace,
    },
    headers: {
      Authorization : `Bearer ${token}`
    }
  }

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function updateSector(bce, sectorCode, sessionId, token) {

  const config = {
    method: 'put',
    url: '/YEnterpriseSectorUser/sector',
    data: {
      bce: bce,
      sector: sectorCode,
      sessionId: sessionId
    },
    headers: {
      Authorization : `Bearer ${token}`
    }
  }

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function updateShareWith(sessionId, sharedWith, token) {

  const config = {
    method: 'put',
    url: '/surveySession/sharedWith',
    data: {
      "sessionId": sessionId,
      "sharedWith": sharedWith
    },
    headers: {
      Authorization : `Bearer ${token}`
    }
  }

  try {
    const response = await client.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function getSectorsByBce(bce, token) {
  try {
    let sectorArray = [];
    const response = await getEnterpriseData(bce, token);
    if (response?.content?.enterprise) {
      const responseEnterprise = response?.content?.enterprise;
      if (responseEnterprise?.activities?.activitiesByNace) {
        for (let i = 0; i < responseEnterprise?.activities?.activitiesByNace.length; i++) {
          sectorArray.push(responseEnterprise?.activities?.activitiesByNace[i]?.code);
        }
        sectorArray = uniq(sectorArray);
        return await getNaceWithSector(sectorArray, token);
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getEthnosUrl(sessionId, sectorCode, token) {
  try {
    const config = {
      method: 'get',
      url: `/surveySession/ethnosUrl/${sessionId}/${sectorCode}`,
      headers: {
        Authorization : `Bearer ${token}`
      }
    }

    const response = await client.request(config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function publishReport(sessionId, bce, namespace, token) {
  try {
    const reportUrl = `${window.location.origin}/report/${sessionId}`
  
    const config = {
      method: 'get',
      url: `/print/${encodeURIComponent(reportUrl)}?bce=${encodeURIComponent(bce)}&session=${encodeURIComponent(sessionId)}&namespace=${namespace}`,
      headers: {
        Authorization : `Bearer ${token}`
      }
    }

    const response = await client.request(config);
    return response.data;
  } catch(error) {
    console.error(error);
  }
}

export {
  getNaceWithSector,
  getPartners,
  getSurveySession,
  getSurveySessionByUser,
  getReferenceByNamespaceCategoryId,
  getReferences,
  createSurvey,
  finishSurvey,
  updateSector,
  updateShareWith,
  getSectorsByBce,
  getEthnosUrl,
  publishReport
}
