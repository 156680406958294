import { useTranslation } from "react-i18next";
import './ErrorPage.css';
import { useNavigate } from "react-router-dom";

function ErrorPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="error-page">
            <p>{t('error.default')}</p>
            <button className="btn" onClick={() => navigate('/')}>{t('error.btn')}</button>
        </div>
    )
}

export default ErrorPage;