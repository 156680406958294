import Tick from "../Tick/Tick"

/**
 * JSX Component for displaying a histogram highlighting the company score(s) and the mean reference value.
 * @param {Object} props - The properties object.
 * @param {Number} props.width - Container width.
 * @param {Number} props.height - Container height.
 * @param {Function} props.scale - Scaling funtion. Depend on axis direction. Either x scale or y scale.
 * @param {string} props.title - Axis title.
 * @param {string[]} props.labels - axis ticks labels.
 * @param {string} props.direction - Tick direction. Either horizontal or vertical.
 * @param {Object} props.paddings - Paddings to apply to the axis. {top: number, bottom: number, left: number, right: number}
 * @param {Function} props.formatFn - Function to format labels.
 * @returns {JSX.Element} The JSX element representing the histogram.
 */
function Axis(props) {

	const formatPadding = props.paddings || {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	}

	if (props.direction === 'horizontal') {
		return (
			<g className="horizontal-axis">
				<text
					style={{
						fontSize: '0.7em',
						textAnchor: 'middle',
						transform: 'translate(95.5%, 72.5%)'
					}}
				>
					{props.title}
				</text>
				<line
					x1={formatPadding.left}
					x2={props.width - (formatPadding.right)}
					y1={props.height - (formatPadding.bottom)}
					y2={props.height - (formatPadding.bottom)}
					stroke="black"
				/>
				{props.labels.map((label, i) => {
					return (
						<Tick
							key={i}
							x={props.scale(label) + props.scale.bandwidth() / 2}
							y={props.height - props.paddings.bottom}
							value={label}
							direction={"vertical"}
							formatFn={props.formatFn}
						/>
					);
				}
				)}
			</g>
		)
	} else {
		return (
			<g className="vertical-axis">
				<text
					y="20px"
					style={{
						fontSize: '0.7em',
						textAnchor: 'middle',
						transform: 'translate(-1.5%, 45%) rotate(-90deg)'
					}}
				>
					{props.title}
				</text>
				<line
					x1={formatPadding.left}
					x2={formatPadding.left}
					y1={formatPadding.top}
					y2={props.height - formatPadding.bottom}
					stroke="black"
				/>
				{props.labels.map((label, i) => {
					return (
						<Tick
							key={i}
							x={props.paddings.left}
							y={props.scale(label)}
							value={label}
							direction={"horizontal"}
							formatFn={props.formatFn}
						/>
					);
				}
				)}
			</g>
		)
	}

}

export default Axis;