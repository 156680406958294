import './Tick.css';

/**
 * JSX Component for displaying a axis tick on a graph.
 * @param {Object} props - The properties object.
 * @param {Number} props.x - Tick x position.
 * @param {Number} props.y - Tick y position.
 * @param {string} props.value - Tick label value.
 * @param {string} props.direction - Tick direction. Either horizontal or vertical.
 * @param {function} props.formatFn - Function to format props.value
 * @returns {JSX.Element} The JSX element representing the histogram.
 */
function Tick(props) {

  return (
    <g className="tick" style={{
      transform: `translate(${props.x}px, ${props.y}px)`
    }}>
      <text
        className={`tick-label ${props.direction}`}
        y={props.direction === "horizontal" ? 3 : 20}
        x={props.direction === "vertical" ? 0 : -10}
        alignmentBaseline='middle'
        style={{
          textAnchor: `${props.direction === "horizontal" ? "end" : "middle"}`,
        }}
      >
        {props.formatFn ? props.formatFn(props.value.toString()) : props.value}
      </text>
      {props.direction === "horizontal" ?
        <line
          x1={-6}
          x2={0}
          y1={0}
          y2={0}
          stroke="black"
          strokeWidth={1}
        />
        :
        <line
          x1={0}
          x2={0}
          y1={2}
          y2={8}
          stroke="black"
          strokeWidth={1}
        />
      }
    </g>
  )
}

export default Tick;