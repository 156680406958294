import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';
import './ResultFilter.css';

function ResultFilter({
  name,
  options = {},
  dimension,
  onOptionChange,
  cn 
}) {

  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function openHandler() {
    setOpen(!isOpen)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(!isOpen);
    setAnchorEl(null);
  };

  function onMenuItemClickHandler(dimension, key) {
    setOpen(false);
    onOptionChange(dimension, key)
  }

  return (
    <div
      className={`result-filter ${cn || ''}`}
      onClick={openHandler}
    >
      <Button className='filter-button' onClick={(event) => handleClick(event)} >
        <p>{name}</p>
        <div className={`arrow-icon ${isOpen ? 'arrow-rotated' : ''}`}>
          <ExpandMoreIcon fontSize='large' />
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        <div className={`result-filter__options ${isOpen ? 'open' : ''}`}>
          {Object.entries(options).map(([key, value], index) => (
            <MenuItem className='result-filter-item' key={index} onClick={() => onMenuItemClickHandler(dimension, key)}>
              <p>{value}</p>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  )
}

export default ResultFilter;