import {
    Backdrop,
    CircularProgress,
} from '@mui/material';

function Loading() {

    return (
        <div className="survey-list__container">
          <Backdrop
              sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  width: '120px !important',
                  height: '120px !important',
                  color: '#ffffff !important'
                }}
              />
            </Backdrop>
        </div>
    )
}

export default Loading;