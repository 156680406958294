import { useTranslation } from "react-i18next"
import explicationScoreImg from '../../assets/images/explication-score-min.jpg';
import DigiscoreLogo from '../../assets/images/logos/logo-digiscore.jpg';
import { ReactComponent as Axe1Infrastructure } from '../../assets/images/svg/axe_1_infrastructure.svg';
import { ReactComponent as Axe2Organisation } from '../../assets/images/svg/axe_2_organisation.svg';
import { ReactComponent as Axe3Processus } from '../../assets/images/svg/axe_3_processus.svg';
import { ReactComponent as Axe4Strategy } from '../../assets/images/svg/axe_4_strategie.svg';
import './Report.css';
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSurveySession } from "../../api/digiscore-api";
import SurveyResultComponent from "../../components/SurveyResultComponent/SurveyResultComponent";
import HowToRead from "../../components/HowToRead/HowToRead";
import { getReferences } from "../../api/digiscore-api";
import RessourcesPage from "../Ressources/RessourcesPage";
import Loading from "../../components/Loading/Loading";
// import jsPDF from 'jspdf';

function findReferenceIndex(references, namespace, dimension, category) {
  if (!references) return undefined;

  const year = namespace === "BaaS20E" ? 2020 : 2022;

  return references.findIndex((ref) => ref.year = year && ref.dimension === dimension && ref.category === category);
}

function getProvinceCategoryByName(province) {
  if (province === "Luxembourg") return "LUX";
  if (province === "Liège") return "LG";
  if (province === "Brabant Wallon") return "BW";
  if (province === "Namur") return "NAM";
  if (province === "Hainaut") return "HAI";

  return undefined;
}

function Report(props) {
  const { t, i18n } = useTranslation();
  const { sessionId } = useParams();
  const navigate = useNavigate();

  const contentRef = useRef();

  const [isLoading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [isLoadingPdf, setLoadingPdf] = useState(false);
  const [responses, setResponses] = useState([]);
  const [survey, setSurvey] = useState({});
  const [enterprise, setEnterprise] = useState({});
  const [referenceGlobal, setReferenceGlobal] = useState();
  const [referenceSector, setReferenceSetor] = useState();
  const [referenceProvince, setReferenceProvince] = useState();
  const [referenceTargetMarket, setReferenceTargetMarket] = useState();
  const [referenceNONEXP, setReferenceNONEXP] = useState(0)

  let dataGlobal = [
    {
      infrastructure: referenceGlobal?.infrastructure?.Mean,
      organization: referenceGlobal?.humanResources?.Mean,
      processes: referenceGlobal?.processFlow?.Mean,
      strategy: referenceGlobal?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ]

  let dataNONEXP = [
    {
      infrastructure: referenceNONEXP?.infrastructure?.Mean,
      organization: referenceNONEXP?.humanResources?.Mean,
      processes: referenceNONEXP?.processFlow?.Mean,
      strategy: referenceNONEXP?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ]

  let dataSector = [
    {
      infrastructure: referenceSector?.infrastructure?.Mean,
      organization: referenceSector?.humanResources?.Mean,
      processes: referenceSector?.processFlow?.Mean,
      strategy: referenceSector?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ]

  let dataProvince = [
    {
      infrastructure: referenceProvince?.infrastructure?.Mean,
      organization: referenceProvince?.humanResources?.Mean,
      processes: referenceProvince?.processFlow?.Mean,
      strategy: referenceProvince?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ]

  let dataTargetMarket = [
    {
      infrastructure: referenceTargetMarket?.infrastructure?.Mean,
      organization: referenceTargetMarket?.humanResources?.Mean,
      processes: referenceTargetMarket?.processFlow?.Mean,
      strategy: referenceTargetMarket?.salesProducts?.Mean,
      name: t('result.histogram.mean'),
    },
    {
      infrastructure: survey?.infrastructure,
      organization: survey?.humanRessources,
      processes: survey?.processFlow,
      strategy: survey?.salesProducts,
      name: `${enterprise.name} (${new Date(survey.completedAt).toLocaleDateString()})`,
    },
  ]

  function getSkippedQuestionsBefore(responses, questionIndex) {
    return responses.slice(0, questionIndex).filter((q) => q.question.hidden || !q.answer || (typeof q.answer !== 'string' && q.answer.length < 1)).length;
  }

  function isAxisVisible(answer, question, axis) {
    if (answer[axis]) {
      return answer[axis] !== '' && question.answered;
    }
    return false;
  }

  function getAnswserAxisClass(answer, question, axis) {
    const axisMeta = answer[axis];
    if ((axisMeta === '1' || axisMeta === '2') && question.answered) {
      return 'success';
    }
    if (axisMeta === '0' && question.answered) {
      return 'fail';
    }
    return '';
  }

  useEffect(() => {
    async function fetchData() {
      await i18n.changeLanguage('fr');
      try {
        const session = await getSurveySession(sessionId, props.token);
        if (session) {
          setSurvey(session);
          setResponses(session.responses);
          setEnterprise(session.enterprise);
        }

        const response = await getReferences(props.token);
        // const response = await getReferenceByNamespaceCategoryId(enterpriseResult?.surveySessions[0]?.namespace, 'Global', 'GLOB', props.token);
        if (response.length > 1) {
          let refIndex = findReferenceIndex(response, session?.enterprise?.surveySessions[0]?.namespace, 'Global', 'GLOB');
          if (refIndex !== -1) {
            setReferenceGlobal(response[refIndex]);
          }

          refIndex = findReferenceIndex(response, session?.enterprise?.surveySessions[0]?.namespace, 'Export', 'NONEXP');
          if (refIndex !== -1) {
            setReferenceNONEXP(response[refIndex]);
          }

          refIndex = findReferenceIndex(response, session?.enterprise?.surveySessions[0]?.namespace, 'Secteur', session?.enterprise?.sectorCode);
          if (refIndex !== -1) {
            setReferenceSetor(response[refIndex]);
          }

          const provinceCategory = getProvinceCategoryByName(session?.enterprise?.address?.province)
          refIndex = findReferenceIndex(response, session?.enterprise?.surveySessions[0]?.namespace, 'Province', provinceCategory);
          if (refIndex !== -1) {
            setReferenceProvince(response[refIndex]);
          }

          refIndex = findReferenceIndex(response, session?.enterprise?.surveySessions[0]?.namespace, 'Marché', session?.targetMarket?.Code);
          if (refIndex !== -1) {
            setReferenceTargetMarket(response[refIndex]);
          }
        }

        setLoading(false);
      } catch (error) {
        console.log(error)
        navigate('/error')
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [sessionId, props.token])

  if (!isLoading) {
    return (
      <div>
        {isLoadingPdf && (
          <Loading />
        )}
        <div ref={contentRef} className="print-container">
          <div className="page">
            <div className="text-center">
              <img src={DigiscoreLogo} alt='Digiscore logo' />
            </div>
            <br /><br />
            <h1 className="text-center">
              {t('report.title')}
              <br /><br />
              <span className="color-black">{enterprise.name}</span>
              <br /><br />
            </h1>
            <h2 className="text-center">
              {t('report.etablishedByAdn', { year: survey.namespace === "BaaS22E" ? 2022 : 2020 })}
            </h2>
            <br /><br />
            <h1 className="text-center">
              {t('report.surveyCompleted')}<br />
              {t('report.surveyCompletedBy', { name: survey.user?.email })} {new Date(survey.completedAt).toLocaleDateString()}
            </h1>
            <br /><br />
            <div className="score-container">
              <table cellPadding="0" cellSpacing="0" className="score-table">
                <tr>
                  <td
                    style={{
                      fontSize: "1.2rem"
                    }}
                  >
                    <div
                      style={{
                        height: "48px",
                        lineHeight: "48px"
                      }}
                    >
                      {t('report.globalScore')}
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "1.2rem"
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        border: "2px solid #52c3f1",
                        borderRadius: "50%",
                        width: "48px",
                        height: "48px",
                        lineHeight: "48px",
                        textAlign: "center",
                        overflow: "hidden"
                      }}
                    >
                      {survey.globalScore}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      padding: ".5rem 0 .5rem 0",
                      fontStyle: "italic",
                      color: "#52c3f1",
                    }}
                  >
                    {t('report.scoreDistribution')} :
                  </td>
                </tr>
                <tr>
                  <td className="distribution-cell">
                    {survey.namespace === "BaaS20E" ?
                      t('report.infrastructureTitle')
                      :
                      t('result.content.chapter1.li1.title')
                    }
                  </td>
                  <td className="distribution-cell-score">{survey.infrastructure}</td>
                </tr>
                <tr>
                  <td className="distribution-cell">
                    {survey.namespace === "BaaS20E" ?
                      t('report.humanResourcesTitle')
                      :
                      t('result.content.chapter1.li2.title')
                    }
                  </td>
                  <td className="distribution-cell-score">{survey.humanRessources}</td>
                </tr>
                <tr>
                  <td className="distribution-cell">
                    {survey.namespace === "BaaS20E" ?
                      t('report.processFlowTitle')
                      :
                      t('result.content.chapter1.li3.title')
                    }
                  </td>
                  <td className="distribution-cell-score">{survey.processFlow}</td>
                </tr>
                <tr>
                  <td className="distribution-cell">
                    {survey.namespace === "BaaS20E" ?
                      t('report.salesProductsTitle')
                      :
                      t('result.content.chapter1.li4.title')
                    }
                  </td>
                  <td className="distribution-cell-score">{survey.salesProducts}</td>
                </tr>
              </table>
            </div>
          </div>

          <div className="multipages">
            {survey.namespace === "BaaS20E" ?
              <>
                <h1>{t('result.content.title')}</h1><br />
                <p>{t('report.howToRead')}</p>
              </>
              :
              <HowToRead />
            }
            <h1>{t('report.responseTitle')}</h1>
            <div>
              <div className="axis-legend-explain">
                <h4 className="axis-lenged-title">{t('report.axis-legend.title')}</h4>
                <p>{t('report.axis-legend.p1')}</p>
                <img src={explicationScoreImg} alt='' />
                <p>{t('report.axis-legend.p2')}</p>
                <p>{t('report.axis-legend.p3')}</p>
                <p>{t('report.axis-legend.p4')}</p>
                <p>{t('report.axis-legend.p5')}</p>
                <p className="axis-legend-shift">{t('report.axis-legend.p6')}</p>
                <p className="axis-legend-shift">{t('report.axis-legend.p7')}</p>
                <p className="axis-legend-shift">{t('report.axis-legend.p8')}</p>
                <p>{t('report.axis-legend.p9')}</p>
                <p className="axis-legend-shift">{t('report.axis-legend.p10')}</p>
              </div>
              <table className="response-table">
                <thead>
                  <tr>
                    <th className="th-score">
                      <Axe1Infrastructure />
                    </th>
                    <th className="th-score">
                      <Axe2Organisation />
                    </th>
                    <th className="th-score">
                      <Axe3Processus />
                    </th>
                    <th className="th-score">
                      <Axe4Strategy />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {responses.map((response, questionIndex) => {
                    let content = [];
                    if (response.answer && (typeof response.answer === 'string' || response.answer.length > 0)) {
                      if (response.question.isStartTheme) {
                        content.push(
                          <tr key={`theme-${response.question.num}`} className="tr-theme">
                            <td colSpan={5}><span>{response.question.themeName}</span></td>
                          </tr>
                        )
                      }

                      if (!response.question.hidden && response.question.responses !== null) {
                        content.push(
                          <tr key={`question-${response.question.num}`} className="tr-question">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <p className="question">{questionIndex + 1 - getSkippedQuestionsBefore(responses, questionIndex)}. {response.question.title}</p>
                            </td>
                          </tr>
                        )

                        if (typeof response.answer === 'string') {
                          content.push(
                            <tr key={`response-${response.question.num}-answer`}>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="answered">
                                {response.answer}
                              </td>
                            </tr>
                          )
                        } else {
                          response.answer.map((answer, answerIndex) => {
                            content.push(
                              <tr key={`response-${response.question.num}-${answerIndex}`}>
                                <td className="td-score">
                                  {isAxisVisible(answer, response.question, 'infrastructure') ?
                                    (
                                      <div className={`axis-scoring ${getAnswserAxisClass(answer, response.question, 'infrastructure')}`}>
                                        <Axe1Infrastructure />
                                      </div>
                                    ) : undefined
                                  }
                                </td>
                                <td className="td-score">
                                  {isAxisVisible(answer, response.question, 'humanRessource') ?
                                    (
                                      <div className={`axis-scoring ${getAnswserAxisClass(answer, response.question, 'humanRessource')}`}>
                                        <Axe2Organisation />
                                      </div>
                                    ) : undefined
                                  }
                                </td>
                                <td className="td-score">
                                  {isAxisVisible(answer, response.question, 'processFlow') ?
                                    (
                                      <div className={`axis-scoring ${getAnswserAxisClass(answer, response.question, 'processFlow')}`}>
                                        <Axe3Processus />
                                      </div>
                                    ) : undefined
                                  }
                                </td>
                                <td className="td-score">
                                  {isAxisVisible(answer, response.question, 'strategy') ?
                                    (
                                      <div className={`axis-scoring ${getAnswserAxisClass(answer, response.question, 'strategy')}`}>
                                        <Axe4Strategy />
                                      </div>
                                    ) : undefined
                                  }
                                </td>
                                <td className={`td-answer-label ${answer.answered ? 'answered' : ''}`}>
                                  {response.answer.length > 1 ?
                                    `${answerIndex + 1}. ${answer.label}`
                                    : undefined
                                  }
                                  {answer.description ?
                                    <>
                                      <br /><small><span></span>{answer.description}</small>
                                    </>
                                    : undefined
                                  }
                                </td>
                              </tr>
                            );
                            return undefined;
                          });
                        }
                      }
                    }
                    return content || undefined
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="page">
            <SurveyResultComponent
              filterTitle={t('result.subtitles.global', { year: enterprise?.surveySessions[0]?.namespace === "BaaS20E" ? 2020 : 2022 })}
              enterprise={enterprise}
              reference={referenceGlobal}
              survey={survey}
              histogramData={Object.values(referenceGlobal.score).map(s => parseFloat(s))}
              histogramLabels={Object.keys(referenceGlobal.score)}
              radarData={dataGlobal}
            />
          </div>

          {referenceSector && <div className="page">
            <SurveyResultComponent
              filterTitle={t('result.subtitles.sector', { value: t(`sector.${enterprise.sectorCode}`).toLowerCase(), year: survey?.namespace === 'BaaS22E' ? 2022 : 2020, interpolation: { escapeValue: false } })}
              enterprise={enterprise}
              reference={referenceSector}
              survey={survey}
              histogramData={Object.values(referenceSector.score).map(s => parseFloat(s))}
              histogramLabels={Object.keys(referenceSector.score)}
              radarData={dataSector}
            />
          </div>}

          {referenceProvince && <div className="page">
            <SurveyResultComponent
              filterTitle={t('result.subtitles.province', { value: t(`province.${getProvinceCategoryByName(enterprise.address.province)}`).toLowerCase(), year: survey?.namespace === 'BaaS22E' ? 2022 : 2020, interpolation: { escapeValue: false } })}
              enterprise={enterprise}
              reference={referenceProvince}
              survey={survey}
              histogramData={Object.values(referenceProvince.score).map(s => parseFloat(s))}
              histogramLabels={Object.keys(referenceProvince.score)}
              radarData={dataProvince}
            />
          </div>}


          {referenceTargetMarket &&
            <div className="page">
              <SurveyResultComponent
                filterTitle={t('result.subtitles.targetMarket', { value: t(`targetMarket.${survey.targetMarket.Code}`).toLowerCase(), year: survey?.namespace === 'BaaS22E' ? 2022 : 2020, interpolation: { escapeValue: false } })}
                enterprise={enterprise}
                reference={referenceTargetMarket}
                survey={survey}
                histogramData={Object.values(referenceTargetMarket.score).map(s => parseFloat(s))}
                histogramLabels={Object.keys(referenceTargetMarket.score)}
                radarData={dataTargetMarket}
              />
            </div>
          }

          {referenceNONEXP &&
            <div className="page">
              <SurveyResultComponent
                filterTitle={t('result.subtitles.export', { value: t(`export.NONEXP`).toLowerCase(), year: survey?.namespace === 'BaaS22E' ? 2022 : 2020, interpolation: { escapeValue: false } })}
                enterprise={enterprise}
                reference={referenceNONEXP}
                survey={survey}
                histogramData={Object.values(referenceNONEXP.score).map(s => parseFloat(s))}
                histogramLabels={Object.keys(referenceNONEXP.score)}
                radarData={dataNONEXP}
              />
            </div>
          }

          <div className="multipage">
            <RessourcesPage />
          </div>
        </div>
      </div>
      
    )
  } else {
    return <Loading />
  }
}

export default Report;